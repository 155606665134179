export const URL = {
  // API_BASE_URL: "http://gamesdeveloper.in:3002",
  // API_BASE_URL: "http://localhost:5500",
// 
  // API_BASE_URL: "http://192.168.0.16.:8000",
  API_BASE_URL: "https://frontapi.devhubtech.com",
  



  createService: "/api/createService",
  getServicesPaginate: "/api/getServicesPaginate?page=",
  getServices: "/api/getServices",
  deleteService: "/api/deleteService?id=",
  deleteProject: "/api/deleteProject?id=",
  // getProjectsPaginate: "/api/getProjectsPaginate",
  getProjectsPaginate: "/api/getProjectsWithDetails?page=",
  createProject: "/api/createProject",
  updateProject: "/api/updateProject",
  updateProjectDetails: "/api/updateProjectDetails",
  addProjectDetails: "/api/addProjectDetails",
  getProjectsDetails: "/api/getProjectsDetails?projectid=",
  updateLocation: "/api/updateLocation",
  getSettings: "/api/getSettings",
  getCategoryList: "/adminApi/getCategoryList",

  getPermissionsList: "/api/getPermissionsList",
  getRoleList: "/api/getRoleList",
  createRoles: "/api/createRoles",
  updateProjectImage: "/api/updateProjectImage",
  getPackageList: "/api/getContactEnquries",
  createCategory: "/api/createBlog",

  getProductList: "/adminApi/getProductList",
  createProduct: "/adminApi/createProduct",
  deleteProduct: "/adminApi/deleteProduct",
  statusProduct: "/adminApi/statusProduct",
  deleteProjectImage: "/api/deleteProjectImage?id=",


  deleteCategory: "/adminApi/deleteCategory",
  statusCategory: "/adminApi/statusCategory",
  statusSubCategory: "/adminApi/statusSubCategory",
  statusSubSubCategory: "/adminApi/statusSubSubCategory",
  getSellerList: "/adminApi/getSellerList",
  statusSeller: "/adminApi/statusSeller",
  deleteseller: "/adminApi/deleteseller",
  getsubsubcate: "/adminApi/getsubsubcate",
  getSellerListforpro: "/adminApi/getSellerListforpro",
  getcateAllList: "/adminApi/getcateAllList",
  subcreateCategory: "/adminApi/subcreateCategory",
  getsubCategoryList: "/adminApi/getsubCategoryList",
  getsubsubCategoryList: "/adminApi/getsubsubCategoryList",
  getsubcateAllList: "/adminApi/getsubcateAllList",
  subsubcreateCategory: "/adminApi/subsubcreateCategory",
  searchProduct: "/adminApi/searchProduct",
  createPackage: "/adminApi/createPackage",
  deletePackage: "/adminApi/deletePackage",
  deletesubCategory: "/adminApi/deletesubCategory",
  deletesubsubCategory: "/adminApi/deletesubsubCategory",
  updatePackage: "/adminApi/updatePackage",

  searchPackage: "/adminApi/searchPackage",
  getTransactionList: "/adminApi/getTransactionList",
  searchtransaction: "/adminApi/searchtransaction",
  createSellerByAdmin: "/adminApi/createSellerByAdmin",
  createTickets: "/adminApi/createTickets",
  getTicketList: "/adminApi/getTicketList",
  getmessageSupportList: "/adminApi/getmessageSupportList",
  createMessage: "/adminApi/createMessage",
  searchTicket: "/adminApi/searchTicket",
  updateProduct: "/adminApi/updateProduct",
  copyProduct: "/adminApi/copyProduct",
  getInvoice: "/adminApi/getInvoice",
  getsellerprofile: "/adminApi/getsellerprofile",
  createAbout: "/adminApi/createAbout",
  getPages: "/adminApi/getPages",
  createPrivacyplicys: "/adminApi/updatePage",
  getWhatsappinquire: "/adminApi/getWhatsappinquire",
  searchWhatsappinquire: "/adminApi/searchWhatsappinquire",
  getSubscription: "/adminApi/getSubscription",
  searchSubscription: "/adminApi/searchSubscription",
  getAdminBussProfile: "/adminApi/getBussProfile",
  createBussnissPro: "/adminApi/createBussnissPro",
  verifyWhatsapp: "/adminApi/verifyWhatsapp",
  updateWhatsaapNumber: "/adminApi/updateWhatsaapNumber",
  updateBussProfile: "/adminApi/updateBussProfile",
  updateBussLogo: "/adminApi/updateBussLogo",
  getInvoceDetails: "/adminApi/getInvoceDetails",
  createAllsettings: "/adminApi/createAllsettings",
  updateInvoiceLogo: "/adminApi/updateInvoiceLogo",
  updateHomeLogo: "/adminApi/updateHomeLogo",
  updateInvoiceProfile: "/adminApi/updateInvoiceProfile",
  getPackageListbuy: "/adminApi/getPackageListbuy",
  createPurchaseAdmin: "/adminApi/createPurchaseAdmin",
  getUpdateTransactionList: "/adminApi/getUpdateTransactionList",
  getEmailenquiry: "/adminApi/getEmailenquiry",
  searchEmailenquiry: "/adminApi/searchEmailenquiry",
  getpageList: "/adminApi/getpageList",
  deletePage: "/adminApi/deletePage",
  createTags: "/adminApi/createTags",
  gettagsList: "/adminApi/gettagsList",
  deletetags: "/adminApi/deletetags",
  updatetags: "/adminApi/updatetags",
  searchtags: "/adminApi/searchtags",
  updatesellerByAdmin: "/adminApi/updatesellerByAdmin",
  createFooterPageType: "/adminApi/createFooterPageType",
  footerPageTypesDelete: "/adminApi/footerPageTypesDelete",
  footerPageTypesUpdate: "/adminApi/footerPageTypesUpdate",
  createFooterPageList: "/adminApi/createFooterPageList",
  footerPageTypes: "/adminApi/footerPageTypes",
  getpageListfooter: "/adminApi/getpageListfooter",
  footerPagepageListet: "/adminApi/footerPagepageListet",
  footerPageListsDelete: "/adminApi/footerPageListsDelete",
  updateperentCate: "/adminApi/updateperentCate",
  updateperentsubCate: "/adminApi/updateperentsubCate",
  updateperentsubsubCate: "/adminApi/updateperentsubsubCate",
  productdateWise: "/adminApi/productdateWise",
  productYearWise: "/adminApi/productYearWise",
  topViewproduct: "/adminApi/topViewproduct",
  topViewproductBycate: "/adminApi/topViewproductBycate",
  topViewproductBySeller: "/adminApi/topViewproductBySeller",


  
 


  
  
  
  




 
 

};
