import React, {useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
// @ts-ignore
// import CKEditor from '@ckeditor/ckeditor5-react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
// @ts-ignore
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import {URL} from '../../../redux/common/url'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../../redux/common/action'
import {Button, Modal} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {toast} from 'react-toastify'

// import CKEditorTest from './CKExampleComponent'

function PageList() {
  const dispatch = useDispatch()

  // const get_pages: any = useSelector((state: any) =>
  //   state.admin.get_pages ? state.admin.get_pages : {}
  // )

  const [show, setShow] = useState<any>(false)
  const handleClose = () => setShow(false)
  const [showEdit, setShowEdit] = useState<any>(false)
  const handleCloseEdit = () => setShowEdit(false)
  console.log(show)

  // get_pages
  const [aboutPage, setaboutPage] = useState<any>('')
  const [privecyPage, setprivecyPage] = useState<any>('')

  const get_pages: any = useSelector((state: any) =>
    state.admin.get_pages ? state.admin.get_pages : {}
  )

  console.log(aboutPage)
  console.log(get_pages)

  useEffect(() => {
    dispatch(adminAction.getpageList(1))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }, [])

  useEffect(() => {
    if (get_pages?.aboutPage?.containt) {
      // setaboutPage(get_pages?.aboutPage?.containt)
    }
    if (get_pages?.privacyplicys?.containt) {
      // setprivecyPage(get_pages?.privacyplicys?.containt)
    }
  }, [get_pages])

  // createAbout

  const [PageData, setPageData] = useState<any>({
    title: '',
    metaTag: '',
    metakeyword: '',
    metadescription: '',
  })
  const hendleChangerole = (e: any) => {
    const {name, value} = e.target
    setPageData({...PageData, [name]: value})
  }
  const [PageDataEdit, setPageDataEdit] = useState<any>({
    title: '',
    metaTag: '',
    metakeyword: '',
    metadescription: '',
    id: '',
  })

  console.log(PageDataEdit)

  // setprivecyPage

  const hendleEditPage = (e: any) => {
    setShowEdit(true)
    setprivecyPage(e?.containt)
    setPageDataEdit({
      title: e?.title,
      metaTag: e?.metaTag,
      metakeyword: e?.metakeyword,
      metadescription: e?.metadescription,
      id: e?._id,
    })
  }

  const hendleChangeroleEdit = (e: any) => {
    const {name, value} = e.target
    setPageDataEdit({...PageDataEdit, [name]: value})
  }

  const hendleAboutUpdate = () => {
    // statusProduct

    if (!PageData?.title) {
      toast.error('enter title')
    } else if (!PageData?.metaTag) {
      toast.error('enter meta tage')
    } else if (!PageData?.metakeyword) {
      toast.error('enter meta keyword')
    } else if (!PageData?.metadescription) {
      toast.error('enter meta description')
    } else if (!aboutPage) {
      toast.error('enter containt')
    } else {
      PageData.containt = aboutPage
      setShow(false)
      dispatch(adminAction.createAbout(PageData))
      // dispatch(adminAction.getRoleList('dhsg'))
      return () => {}
    }
  }

  const hendlePrivecyUpdate = () => {
    // statusProduct
    if (!PageDataEdit?.title) {
      toast.error('enter title')
    } else if (!PageDataEdit?.metaTag) {
      toast.error('enter meta tage')
    } else if (!PageDataEdit?.metakeyword) {
      toast.error('enter meta keyword')
    } else if (!PageDataEdit?.metadescription) {
      toast.error('enter meta description')
    } else if (!privecyPage) {
      toast.error('enter containt')
    } else {
      PageDataEdit.containt = privecyPage
      dispatch(adminAction.createPrivacyplicys(PageDataEdit))
      setShowEdit(false)
      // dispatch(adminAction.getRoleList('dhsg'))
      return () => {}
    }
  }

  const API_URl = URL.API_BASE_URL
  const UPLOAD_ENDPOINT = 'publicApi/productcon'

  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file.then((file: any) => {
            body.append('upload', file)
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: 'post',
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res);
                resolve({default: `${res.url}`})
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    }
  }
  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader)
    }
  }
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')
  // const [comments, setComments] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(1)

  const [itemsPerPage, setitemsPerPage] = useState(10)

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState({field: '', order: ''})

  const maxNumber = 1
  const ITEMS_PER_PAGE = 10

  const [productseID, setproductseID] = useState<any>('')
  const [pageNo, setpageNo] = useState<any>(1)

  const comments = get_pages?.docs ? get_pages?.docs : []

  // console.log(comments)

  const headers = [
    {name: 'S.No.', field: '_id', sortable: false},
    {name: 'Title', field: 'title', sortable: true},
    // {name: 'Status', field: 'status', sortable: true},
    // {name: 'Deleted', field: 'delete', sortable: false},
    {name: 'Action', field: 'action', sortable: false},
  ]

  const onSortingChange = (field: any) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    setSorting({field, order})
  }

  const commentsData = useMemo(() => {
    let computedComments = comments

    // if (search) {
    //     computedComments = computedComments.filter(
    //         comment =>
    //             comment.name.toLowerCase().includes(search.toLowerCase()) ||
    //             comment.role_name.toLowerCase().includes(search.toLowerCase())
    //     );
    // }

    // console.log('test')

    setTotalItems(computedComments.length)

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1
      computedComments = computedComments.sort(
        (a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      )
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    )
  }, [get_pages, currentPage, search, sorting])

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setPageCount(Math.ceil(get_pages?.totalDocs / itemsPerPage))
  }, [itemOffset, itemsPerPage, get_pages])

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    if (!productseID) {
      // dispatch(ShoetoggelAction.getusers(data))
      dispatch(adminAction.getpageList(data))

      return () => {}
    } else {
      // dispatch(
      //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
      // );
      // dispatch(adminAction.searchProduct({key: productseID, page: data}))
      // return () => {}
    }
  }

  const [showDelelt, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const [productID, setproductID] = useState<any>('')

  const hendleModalShow = (e: any) => {
    setShowDelete(true)
    setproductID(e)
  }
  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false)
    dispatch(adminAction.deletePage({id: productID}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }

  return (
    <div>
      <Modal show={show} size='lg' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Woohoo, you're reading this text in a modal! */}
          {/* <form
                  // id='kt_modal_update_permission_form'
                  className='form'
                  // action='#'
                  // onSubmit={submitProduct}
                  // noValidate
                > */}

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Title</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              placeholder='Enter  title'
              name='title'
              // onChange={(e) => {
              //   hendleChangerole(e)
              // }}
              // {...formik.getFieldProps('role_name')}
              className='form-control bg-transparent'
              onChange={(e: any) => {
                hendleChangerole(e)
              }}
            />
          </div>
          <CKEditor
            editor={Editor}
            data={aboutPage}
            onReady={(editor: any) => {
              // console.log('SDfsf')
              // console.log(editor)
            }}
            onChange={(event: any, editor: any) => {
              // console.log(editor)
              const data = editor.getData()
              setaboutPage(data)
            }}
            config={{
              extraPlugins: [uploadPlugin],
            }}
            // config={{
            //   // plugins: [ Essentials ],
            //   ckfinder: {
            //     // The URL that the images are uploaded to.
            //     uploadUrl: `${URL.API_BASE_URL}/apiPublic/productcon`,

            //     // Enable the XMLHttpRequest.withCredentials property.
            //     withCredentials: true,

            //     // Headers sent along with the XMLHttpRequest to the upload server.
            //     headers: {
            //       "X-CSRF-TOKEN": "CSFR-Token",
            //       Authorization: "Bearer <JSON Web Token>",
            //     },
            //   },
            // }}
            onBlur={(event: any, editor: any) => {
              console.log('SDfsf')
            }}
            onFocus={(event: any, editor: any) => {
              console.log('SDfsf')
            }}
          />

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Meta Tag</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter meta tag'
              name='metaTag'
              onChange={(e: any) => {
                hendleChangerole(e)
              }}
              // {...formik.getFieldProps('role_description')}
              className='form-control bg-transparent'
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>meta keyword</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <textarea
              // className='form-control form-control-solid'
              placeholder='Enter meta keyword'
              name='metakeyword'
              onChange={(e: any) => {
                hendleChangerole(e)
              }}
              // {...formik.getFieldProps('role_description')}
              className='form-control bg-transparent'
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>metadescription</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <textarea
              // className='form-control form-control-solid'
              placeholder='Enter meta description'
              name='metadescription'
              onChange={(e: any) => {
                hendleChangerole(e)
              }}
              // {...formik.getFieldProps('role_description')}
              className='form-control bg-transparent'
            />
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-permissions-modal-action='cancel'
              onClick={handleClose}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => {
                hendleAboutUpdate()
              }}
              data-kt-permissions-modal-action='submit'
            >
              {<span className='indicator-label'>Submit</span>}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
      </Modal>
      {/* edit */}
      <Modal show={showEdit} size='lg' onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Woohoo, you're reading this text in a modal! */}
          {/* <form
                  // id='kt_modal_update_permission_form'
                  className='form'
                  // action='#'
                  // onSubmit={submitProduct}
                  // noValidate
                > */}

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Title</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              placeholder='Enter  title'
              name='title'
              value={PageDataEdit?.title}
              // onChange={(e) => {
              //   hendleChangerole(e)
              // }}
              // {...formik.getFieldProps('role_name')}
              className='form-control bg-transparent'
              onChange={(e: any) => {
                hendleChangeroleEdit(e)
              }}
            />
          </div>
          <CKEditor
            editor={Editor}
            data={privecyPage}
            onReady={(editor: any) => {
              console.log('SDfsf')
              console.log(editor)
            }}
            onChange={(event: any, editor: any) => {
              console.log(editor)
              const data = editor.getData()
              setprivecyPage(data)
            }}
            config={{
              extraPlugins: [uploadPlugin],
            }}
            onBlur={(event: any, editor: any) => {
              console.log('SDfsf')
            }}
            onFocus={(event: any, editor: any) => {
              console.log('SDfsf')
            }}
          />

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Meta Tag</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter meta tag'
              name='metaTag'
              value={PageDataEdit?.metaTag}
              onChange={(e: any) => {
                hendleChangeroleEdit(e)
              }}
              // {...formik.getFieldProps('role_description')}
              className='form-control bg-transparent'
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>meta keyword</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <textarea
              // className='form-control form-control-solid'
              placeholder='Enter meta keyword'
              name='metakeyword'
              value={PageDataEdit?.metakeyword}
              onChange={(e: any) => {
                hendleChangeroleEdit(e)
              }}
              // {...formik.getFieldProps('role_description')}
              className='form-control bg-transparent'
            />
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>metadescription</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <textarea
              // className='form-control form-control-solid'
              placeholder='Enter meta description'
              name='metadescription'
              value={PageDataEdit?.metadescription}
              onChange={(e: any) => {
                hendleChangeroleEdit(e)
              }}
              // {...formik.getFieldProps('role_description')}
              className='form-control bg-transparent'
            />
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-permissions-modal-action='cancel'
              onClick={handleCloseEdit}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => {
                hendlePrivecyUpdate()
              }}
              data-kt-permissions-modal-action='submit'
            >
              {<span className='indicator-label'>Submit</span>}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
      </Modal>

      {/* delete modal  */}
      <Modal show={showDelelt} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title> Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure
          {/* <div className='mb-10 d-flex'>
                  <div>
                    <h6>Feature Image</h6>
                    <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div>
                    <div>
                      <h6>Product Name</h6>
                      <p>{roleID?.title}</p>
                      <h6>Teg</h6>
                      <p>{roleID?.tegs}</p>
                    </div>
                    <h6>product Image</h6>
                    <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDelete}>
            No
          </Button>
          <Button variant='primary' onClick={hendleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='d-flex flex-column flex-column-fluid'>
        {/* <!--begin::Toolbar-->  */}
        <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          {/* <!--begin::Toolbar container-->  */}
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            {/* <!--begin::Page title-->  */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              {/* <!--begin::Title-->  */}
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                Page
              </h1>
              {/* <!--end::Title-->  */}
              {/* <!--begin::Breadcrumb-->  */}
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>
                  {/* <a href='../../demo1/dist/index.html' className='text-muted text-hover-primary'>
                  Home
                </a> */}
                  <Link to={'/'} className='text-muted text-hover-primary'>
                    Home
                  </Link>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>Page</li>
                {/* <!--end::Item-->  */}
              </ul>
              {/* <!--end::Breadcrumb-->  */}
            </div>
            {/* <!--end::Page title-->  */}
            {/* <!--begin::Actions-->  */}

            {/* <!--end::Actions-->  */}
          </div>
          {/* <!--end::Toolbar container-->  */}
        </div>
        {/* <!--end::Toolbar-->  */}
        {/* <!--begin::Content-->  */}
        <div id='kt_app_content' className='app-content flex-column-fluid'>
          {/* <!--begin::Content container-->  */}
          <div id='kt_app_content_container' className='app-container container-xxl'>
            {/* <!--begin::Card-->  */}
            <div className='card card-flush'>
              {/* <!--begin::Card header-->  */}
              <div className='card-header mt-6'>
                {/* <!--begin::Card title-->  */}
                <div className='card-title'>
                  {/* <!--begin::Search-->  */}
                  <div className='d-flex align-items-center position-relative my-1 me-5'>
                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->  */}
                    {/* <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.5'
                          x='17.0365'
                          y='15.1223'
                          width='8.15546'
                          height='2'
                          rx='1'
                          transform='rotate(45 17.0365 15.1223)'
                          fill='currentColor'
                        />
                        <path
                          d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span> */}
                    Page
                    {/* <!--end::Svg Icon-->  */}
                    {/* <input
                      type='text'
                      data-kt-permissions-table-filter='search'
                      className='form-control form-control-solid w-250px ps-15'
                      placeholder='Search package'
                      // onChange={(e: any) => {
                      //   hendleSeacrhPro(e.target.value)
                      // }}
                    /> */}
                  </div>
                  {/* <!--end::Search-->  */}
                </div>
                {/* <!--end::Card title-->  */}
                {/* <!--begin::Card toolbar-->  */}
                <div className='card-toolbar'>
                  {/* <!--begin::Button-->  */}
                  <button
                    type='button'
                    className='btn btn-light-primary'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_add_permission'
                    onClick={() => {
                      setShow(true)
                    }}
                  >
                    <span className='svg-icon svg-icon-3'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.3'
                          x='2'
                          y='2'
                          width='20'
                          height='20'
                          rx='5'
                          fill='currentColor'
                        />
                        <rect
                          x='10.8891'
                          y='17.8033'
                          width='12'
                          height='2'
                          rx='1'
                          transform='rotate(-90 10.8891 17.8033)'
                          fill='currentColor'
                        />
                        <rect
                          x='6.01041'
                          y='10.9247'
                          width='12'
                          height='2'
                          rx='1'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    Add Page
                  </button>
                  {/* <!--end::Button-->  */}
                  {/* <Button variant='primary' onClick={handleShow}>
                Launch demo modal
              </Button> */}
                </div>
                {/* <!--end::Card toolbar-->  */}
              </div>
              {/* <!--end::Card header-->  */}
              {/* <!--begin::Card body-->  */}
              <div className='card-body pt-0'>
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                  id='kt_permissions_table'
                >
                  {/* <!--begin::Table head-->  */}
                  <thead>
                    <tr>
                      {headers.map((data: any, i: any) => (
                        <th
                          key={data?.name}
                          onClick={() => (data?.sortable ? onSortingChange(data?.field) : null)}
                        >
                          {data?.name}

                          {sortingField &&
                            sortingField === data?.field &&
                            (sortingOrder === 'asc' ? (
                              //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                              <i className='fa-solid fa-arrow-down'></i>
                            ) : (
                              <i className='fas fa-arrow-alt-up'></i>
                            ))}
                        </th>
                      ))}
                    </tr>

                    {/* <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) => setSorting({field, order})}
                  /> */}
                    {/* <!--end::Table row-->  */}
                  </thead>
                  {/* <!--end::Table head-->  */}
                  {/* <!--begin::Table body-->  */}
                  <tbody className='fw-semibold text-gray-600'>
                    {commentsData?.map((data: any, i: any) => {
                     const So = 1 + i
                     const pgt1 = pageNo - 1
                     const pgt = pgt1 * 10
                     const soNo = pgt + So
                      return (
                        <tr>
                          {/* <!--begin::Name=-->  */}
                          {/* <td>User Management</td> */}
                          {/* <!--end::Name=-->  */}
                          {/* <!--begin::Assigned to=-->  */}
                          <td>
                            {soNo}
                            {/* <a
                            href='../../demo1/dist/apps/user-management/roles/view.html'
                            className='badge badge-light-primary fs-7 m-1'
                          >
                            Administrator
                          </a> */}
                          </td>
                          {/* <!--end::Assigned to=-->  */}
                          {/* <!--begin::Created Date-->  */}
                          <td>{data?.title}</td>

                          {/* <td>{data?.delete ? 'Yes' : 'No'}</td> */}
                          {/* <!--end::Created Date-->  */}
                          {/* <!--begin::Action=-->  */}
                          <td>
                            {/* <!--begin::Update-->  */}

                            {/* <td className="text-end"> */}

                            {/* <Dropdown>
                              <Dropdown.Toggle
                                variant='btn btn-light btn-active-light-primary btn-sm'
                                id='dropdown-basic'
                              >
                                Action
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                     
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleShowCopyPro(data?._id)
                                  }}
                                >
                                  Copy
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleToaddPer(data)
                                  }}
                                >
                                  View
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleeditProductBox(data)
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleModalShow(data?._id)
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}

                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleEditPage(data)
                              }}
                              className='btn btn-sm btn-info ms-3'
                            >
                              <i className='fas fa-edit'></i>
                            </button>

                            {/* <button
                              data-id='2209'
                            
                              className='btn btn-sm btn-info viewItem ms-3'
                            >
                              <i className='fas fa-eye'></i>
                            </button> */}
                            <button
                              data-id='2209'
                              onClick={() => {
                                hendleModalShow(data?._id)
                              }}
                              className='btn btn-sm btn-danger ms-3'
                            >
                              <i className='fas fa-trash-alt'></i>
                            </button>
                            {/* <button
                              data-id='2209'
                            
                              className='btn btn-sm btn-primary ms-3'
                            >
                            
                              Copy
                            </button> */}

                            {/* <!--end::Menu--> */}
                            {/* </td> */}
                            {/* <button
                              className='btn btn-icon btn-active-light-primary w-30px h-30px me-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_update_permission'
                            >
                             
                              <span className='svg-icon svg-icon-3'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.3'
                                    d='M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            
                            </button> */}

                            {/* <!--end::Update-->  */}
                            {/* <!--begin::Delete-->  */}
                            {/* <button
                              className='btn btn-icon btn-active-light-primary w-30px h-30px'
                              data-kt-permissions-table-filter='delete_row'
                            >
                            
                              <span className='svg-icon svg-icon-3'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.5'
                                    d='M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    opacity='0.5'
                                    d='M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                           
                            </button> */}
                            {/* <!--end::Delete-->  */}
                          </td>
                          {/* <!--end::Action=-->  */}
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* <!--end::Table body-->  */}
                </table>

                <ReactPaginate
                  breakLabel='...'
                  nextLabel='next'
                  className='dataTables_paginate paging_simple_numbers category'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel='previous'
                  // renderOnZeroPageCount={null}
                />

                {/* <Button
                  onClick={() => {
                    hendleAboutUpdate()
                  }}
                  className='mt-3'
                >
                  submit
                </Button> */}
              </div>

              {/* <!--end::Card body-->  */}
            </div>
            {/* <!--end::Card-->  */}
            {/* <!--begin::Modals-->  */}
            {/* <!--begin::Modal - Add permissions-->  */}

            {/* <!--end::Modal - Add permissions-->  */}
            {/* <!--begin::Modal - Update permissions-->  */}

            {/* <!--end::Modal - Update permissions-->  */}
            {/* <!--end::Modals-->  */}
          </div>
          {/* <!--end::Content container-->  */}
        </div>

        {/* <!--end::Content-->  */}
      </div>
    </div>
  )
}

export default PageList
