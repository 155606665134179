/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../../redux/common/action'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget3,
  ChartsWidget4,
} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => {
  const analitics_prodact_by_date: any[] = useSelector((state: any) =>
    state.admin.analitics_prodact_by_date ? state.admin.analitics_prodact_by_date : []
  )
  const top_view_pro: any[] = useSelector((state: any) =>
    state.admin.top_view_pro ? state.admin.top_view_pro : []
  )
  const top_view_pro_by_cate: any[] = useSelector((state: any) =>
    state.admin.top_view_pro_by_cate ? state.admin.top_view_pro_by_cate : []
  )
  const top_view_pro_by_seller: any[] = useSelector((state: any) =>
    state.admin.top_view_pro_by_seller ? state.admin.top_view_pro_by_seller : []
  )

  // console.log(analitics_prodact_by_date)
  // console.log(top_view_pro)

  var countDataProda: any = []
  var categoriesDataaProda: any = []

  var countViewTotal: any = []
  var proDuctName: any = []

  //  by cate
  var countViewTotalByCate: any = []
  var proDuctNameByCate: any = []
  // seller
  var countViewTotalBySeller: any = []
  var proDuctNameBySeller: any = []

  analitics_prodact_by_date.forEach((row: any) => {
    categoriesDataaProda.push(`${row?._id?.month}/${row?._id?.year}`)
    countDataProda.push(row.total_view)
  })

  top_view_pro_by_cate.forEach((row: any) => {
    countViewTotalByCate.push(row.totalViews)
    proDuctNameByCate.push(row._id)
  })

  top_view_pro.forEach((row: any) => {
    countViewTotal.push(row.totalViews)
    proDuctName.push(row._id)
  })
  top_view_pro_by_seller.forEach((row: any) => {
    countViewTotalBySeller.push(row.totalViews)
    proDuctNameBySeller.push(row._id)
  })

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />
      </div> */}

        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
        <ListsWidget26 className='h-lg-50' />
      </div> */}

        <div className='col-xxl-6'>
          <EngageWidget10 className='h-md-100' />
        </div>
        <div className='row g-5 g-xl-8'>
          {/* <div className='col-xl-6'>
            <ChartsWidget3
              className='card-xl-stretch mb-xl-8'
              y={countDataProda}
              x={categoriesDataaProda}
              type='Product Date Wise'
            />
          </div> */}
          <div className='col-xl-6'>
            {/* <div className='col-xl-6'> */}
            <ChartsWidget3
              className='card-xl-stretch mb-xl-8'
              y={countViewTotal}
              x={proDuctName}
              type='Top Product View'
            />
            {/* </div> */}
            {/* <ChartsWidget4 className='card-xl-stretch mb-5 mb-xl-8' /> */}
          </div>
          <div className='col-xl-6'>
            {/* <div className='col-xl-6'> */}
            <ChartsWidget3
              className='card-xl-stretch mb-xl-8'
              y={countViewTotalByCate}
              x={proDuctNameByCate}
              type='Top Category View'
            />
            {/* </div> */}
            {/* <ChartsWidget4 className='card-xl-stretch mb-5 mb-xl-8' /> */}
          </div>
          <div className='col-xl-6'>
            {/* <div className='col-xl-6'> */}
            <ChartsWidget3
              className='card-xl-stretch mb-xl-8'
              y={countViewTotalBySeller}
              x={proDuctNameBySeller}
              type='Top Seller View'
            />
            {/* </div> */}
            {/* <ChartsWidget4 className='card-xl-stretch mb-5 mb-xl-8' /> */}
          </div>
        </div>
      </div>

      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gx-5 gx-xl-10'>
     
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        
      </div>
           <div className='col-xxl-6 mb-5 mb-xl-10'>
       
      </div>
  
    </div> */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}

      {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
       
      </div>
    </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(adminAction.productdateWise({}))
    // dispatch(adminAction.topViewproduct({}))
    // dispatch(adminAction.topViewproductBycate({}))
    // dispatch(adminAction.topViewproductBySeller({}))

    return () => {}
  }, [])

  // productdateWise
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
