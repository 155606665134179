import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useParams} from 'react-router-dom'
import {adminAction} from '../../../redux/common/action'
import {URL} from '../../../redux/common/url'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'

// @ts-ignore
// import CKEditor from '@ckeditor/ckeditor5-react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
// @ts-ignore
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor'

import ImageUploading from 'react-images-uploading'
import {toast} from 'react-toastify'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const loginSchema = Yup.object().shape({
  role_name: Yup.string()
    // .role_name('Wrong role_name format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('product is required'),
  role_description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('tegs is required'),
})

const initialValues = {
  role_name: '',
  role_description: '',
}

function ProjectDetails() {
  const {id} = useParams()

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const {data: auth} = await login(values.role_name, values.role_description)
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // dispatch(adminAction.createRoles(values))
        const data = new FormData()

        // data.append("title", imageEditfor);

        // images

        data.append('name', values?.role_name)
        data.append('description', values?.role_description)
        data.append('categoryName', 'test cate')
        data.append('parentId', '11')
        data.append('sellerId', '20')

        // data.append('image', images[0]?.file)
        // imagesss?.forEach((file: any) => {
        //   console.log(file?.file)
        //   data.append('images', file?.file)
        // })

        dispatch(adminAction.createCategory(data))
        // setShow(false)
        setLoading(false)
        // setCurrentUser(user)
      } catch (error) {
        // saveAuth(undefined)
        // setStatus('The login details are incorrect')
        // setSubmitting(false)
        // setLoading(false)
      }
    },
  })
  const dispatch = useDispatch()
  console.log(id)

  const get_project_details: any = useSelector((state: any) =>
    state.admin.get_project_details ? state.admin.get_project_details : {}
  )

  console.log(get_project_details)

  useEffect(() => {
    dispatch(adminAction.getProjectsDetails(id))
  }, [])

  const [projectDetailsEditBox, setprojectDetailsEditBox] = useState(false)

  const handleCloseprojectDetailsEditBox = () => setprojectDetailsEditBox(false)

  const [projectDetailsEditBoxImage, setprojectDetailsEditBoxImage] = useState(false)

  const handleCloseprojectDetailsEditBoxImage = () => setprojectDetailsEditBoxImage(false)

  const [projectDetailsEditBoxImageAdd, setprojectDetailsEditBoxImageAdd] = useState(false)

  const handleCloseprojectDetailsEditBoxImageAdd = () => setprojectDetailsEditBoxImageAdd(false)

  const handleShowprojectDetailsEditBox = () => setprojectDetailsEditBox(true)

  const [projectIdDetalis, setprojectIdDetalis] = useState<any>({slug: ''})
  const [projectIdDetalisAdd, setprojectIdDetalisAdd] = useState<any>({slug: ''})
  const [aboutPage, setaboutPage] = useState<any>('')
  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())

  const [imagesss, setImagesss] = React.useState<any>([])
  const [imagesssAdd, setImagesssAdd] = React.useState<any>([])
  const maxNumberss = 8

  const onChangess = (imageList: any, addUpdateIndex: any) => {
    // data for submit

    setImagesss(imageList)
  }

  const hendleTonewPhoto = () => {
    setprojectIdDetalisAdd(get_project_details)

    setprojectDetailsEditBoxImageAdd(true)
  }

  const onChangessAdd = (imageList: any, addUpdateIndex: any) => {
    // data for submit

    setImagesssAdd(imageList)
  }

  const [imagesssUpdateData, setImagesssUpdateData] = React.useState<any>({})

  const hendleToseditImage = (e: any) => {
    setImagesssUpdateData(e)
    setImagesss([{data_url: URL.API_BASE_URL + e.image}])
    setprojectDetailsEditBoxImage(true)
  }

  const submitUpdateImage = () => {
    const data = new FormData()
    if (imagesss[0]?.file) {
      // roledata?.role_name

      data.append('id', imagesssUpdateData?.id)
      data.append('image', imagesss[0]?.file)
      dispatch(adminAction.updateProjectImage(data, id))
    }
    if (imagesss?.length == 0) {
      // roledata?.role_name

      // data.append('id', imagesssUpdateData?.id)
      // data.append('image', imagesss[0]?.file)
      dispatch(adminAction.deleteProjectImage(imagesssUpdateData?.id, id))
    }

    setprojectDetailsEditBoxImage(false)
  }

  const hendleToAdd = (e: any) => {
    const {name, value} = e.target
    setprojectIdDetalis({...projectIdDetalis, [name]: value})
  }

  const hendleToEditProjectDetails = () => {
    setprojectDetailsEditBox(true)
    setprojectIdDetalis(get_project_details)
    setaboutPage(get_project_details?.editorContent)

    const date = new Date(get_project_details?.startAt)
    const edate = new Date(get_project_details?.deliverAt)
    setStartDate(date)
    setEndDate(edate)
  }

  const API_URl = URL.API_BASE_URL
  const UPLOAD_ENDPOINT = 'publicApi/productcon'

  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file.then((file: any) => {
            body.append('upload', file)
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: 'post',
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({default: `${res.url}`})
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    }
  }
  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader)
    }
  }

  const updateDateDetails = () => {
    const data = new FormData()
    data.append('adminLink', projectIdDetalis?.adminLink)
    data.append('appstoreLink', projectIdDetalis?.appstoreLink)
    data.append('clientName', projectIdDetalis?.clientName)
    data.append('demopassword', projectIdDetalis?.demopassword)
    data.append('demopin', projectIdDetalis?.demopin)
    data.append('demousername', projectIdDetalis?.demousername)
    data.append('id', projectIdDetalis?.id)
    data.append('meta_description', projectIdDetalis?.meta_description)
    data.append('meta_title', projectIdDetalis?.meta_title)
    data.append('playstoreLink', projectIdDetalis?.playstoreLink)
    // data.append('slug', projectIdDetalis?.slug)
    data.append('versioncode', projectIdDetalis?.versioncode)
    data.append('versionname', projectIdDetalis?.versionname)
    data.append('projectid', projectIdDetalis?.projectid)
    data.append('webLink', projectIdDetalis?.webLink)

    data.append('editorContent', aboutPage)

    data.append('startAt', moment(startDate).format('YYYY-MM-DD'))
    data.append('deliverAt', moment(endDate).format('YYYY-MM-DD'))

    dispatch(adminAction.updateProjectDetails(data, projectIdDetalis?.projectid))
    setprojectDetailsEditBox(false)
    // updateProjectDetails
  }

  const addmoreimage = () => {
    const data = new FormData()

    if (imagesssAdd?.length > 0) {
      data.append('id', projectIdDetalisAdd?.id)
      data.append('projectid', projectIdDetalisAdd?.projectid)
      imagesssAdd?.forEach((file: any, i: any) => {
        data.append('image[' + i + ']', file?.file)
      })

      dispatch(adminAction.updateProjectDetails(data, projectIdDetalisAdd?.projectid))
      setprojectDetailsEditBoxImageAdd(false)
      setImagesssAdd([])
    } else {
      toast.error('please select image')
    }

    // imagesssAdd
  }

  return (
    <div>
      <Modal show={projectDetailsEditBox} onHide={handleCloseprojectDetailsEditBox}>
        <Modal.Header closeButton>
          <Modal.Title> Detele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>clientName</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a clientName'
              name='clientName'
              value={projectIdDetalis?.clientName}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>slug</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a slug'
              name='slug'
              value={projectIdDetalis?.slug}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          {/* <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>longDescription</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a longDescription'
              name='longDescription'
              value={projectIdDetalis?.longDescription}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div> */}

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>playstoreLink</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a playstoreLink'
              name='playstoreLink'
              value={projectIdDetalis?.playstoreLink}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>appstoreLink</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a appstoreLink'
              name='appstoreLink'
              value={projectIdDetalis?.appstoreLink}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          {/* <div className='form-group col-md-6'>
                  <label>Start Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => setStartDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label>deliver date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: any) => setEndDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control bg-transparent'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div> */}
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Start Dat</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              // showTimeSelect
              //   timeFormat="HH:mm:ss"
              className='form-control'
              //   minDate={new Date()}
              placeholderText='Date & Time'
              dateFormat='yyyy-MM-dd'
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>deliver date</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
              // showTimeSelect
              //   timeFormat="HH:mm:ss"
              className='form-control bg-transparent'
              //   minDate={new Date()}
              placeholderText='Date & Time'
              dateFormat='yyyy-MM-dd'
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>webLink</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a webLink'
              name='webLink'
              value={projectIdDetalis?.webLink}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>adminLink</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a adminLink'
              name='adminLink'
              value={projectIdDetalis?.adminLink}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>demousername</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a demousername'
              name='demousername'
              value={projectIdDetalis?.demousername}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>demopassword</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a demopassword'
              name='demopassword'
              value={projectIdDetalis?.demopassword}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>demopin</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a demopin'
              name='demopin'
              value={projectIdDetalis?.demopin}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>versioncode</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a versioncode'
              name='versioncode'
              value={projectIdDetalis?.versioncode}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>versionname</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <input
              // className='form-control form-control-solid'
              placeholder='Enter a versionname'
              name='versionname'
              value={projectIdDetalis?.versionname}
              onChange={(e) => {
                hendleToAdd(e)
              }}
              // {...formik.getFieldProps('role_name')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                {
                  'is-valid': formik.touched.role_name && !formik.errors.role_name,
                }
              )}
              // onChange={(e:any)=>{hendleToAdd(e)}}
            />
            {formik.touched.role_name && formik.errors.role_name && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.role_name}</span>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Description</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <CKEditor
              editor={Editor}
              data={aboutPage}
              onReady={(editor: any) => {
                // console.log('SDfsf')
                // console.log(editor)
              }}
              onChange={(event: any, editor: any) => {
                // console.log(editor)
                const data = editor.getData()
                setaboutPage(data)
              }}
              config={{
                extraPlugins: [uploadPlugin],
              }}
              // config={{
              //   // plugins: [ Essentials ],
              //   ckfinder: {
              //     // The URL that the images are uploaded to.
              //     uploadUrl: `${URL.API_BASE_URL}/apiPublic/productcon`,

              //     // Enable the XMLHttpRequest.withCredentials property.
              //     withCredentials: true,

              //     // Headers sent along with the XMLHttpRequest to the upload server.
              //     headers: {
              //       "X-CSRF-TOKEN": "CSFR-Token",
              //       Authorization: "Bearer <JSON Web Token>",
              //     },
              //   },
              // }}
              onBlur={(event: any, editor: any) => {
                console.log('SDfsf')
              }}
              onFocus={(event: any, editor: any) => {
                console.log('SDfsf')
              }}
            />
          </div>

          {/* <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Image</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <ImageUploading
              multiple
              value={imagesss}
              onChange={onChangess}
              maxNumber={maxNumberss}
              dataURLKey='data_url'
              acceptType={['png',"jpg", "jpeg", "svg"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className='upload__image-wrapper'>
                  <button
                    className='btn btn-secondary'
                    style={isDragging ? {color: 'red'} : {}}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Choose image
                  </button>
                  &nbsp;
                  <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                    Remove all images
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className='image-item'>
                      <img src={image.data_url} alt='' width='100' />
                      <div className='image-item__btn-wrapper'>
                        <button className='btn btn-secondary' onClick={() => onImageUpdate(index)}>
                          Update
                        </button>
                        <button className='btn btn-secondary' onClick={() => onImageRemove(index)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div> */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-permissions-modal-action='cancel'
              onClick={() => {
                handleCloseprojectDetailsEditBox()
              }}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => {
                updateDateDetails()
              }}
              data-kt-permissions-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDelete}>
            No
          </Button>
          <Button variant='primary' onClick={hendleDelete}>
            Yes
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal show={projectDetailsEditBoxImage} onHide={handleCloseprojectDetailsEditBoxImage}>
        <Modal.Header closeButton>
          <Modal.Title> Update Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Image</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <ImageUploading
              multiple
              value={imagesss}
              onChange={onChangess}
              maxNumber={maxNumberss}
              dataURLKey='data_url'
              acceptType={['png', 'jpg', 'jpeg', 'svg']}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className='upload__image-wrapper'>
                  <button
                    className='btn btn-secondary'
                    style={isDragging ? {color: 'red'} : {}}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Choose image
                  </button>
                  &nbsp;
                  <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                    Remove all images
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className='image-item'>
                      <img src={image.data_url} alt='' width='100' />
                      <div className='image-item__btn-wrapper'>
                        <button className='btn btn-secondary' onClick={() => onImageUpdate(index)}>
                          Update
                        </button>
                        <button className='btn btn-secondary' onClick={() => onImageRemove(index)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-permissions-modal-action='cancel'
              onClick={() => {
                handleCloseprojectDetailsEditBoxImage()
              }}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => {
                submitUpdateImage()
              }}
              data-kt-permissions-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDelete}>
            No
          </Button>
          <Button variant='primary' onClick={hendleDelete}>
            Yes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={projectDetailsEditBoxImageAdd} onHide={handleCloseprojectDetailsEditBoxImageAdd}>
        <Modal.Header closeButton>
          <Modal.Title> Add image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='fv-row mb-7'>
            <label className='fs-6 fw-semibold form-label mb-2'>
              <span className='required'>Image</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='popover'
                data-bs-trigger='hover'
                data-bs-html='true'
                data-bs-content='Permission names is required to be unique.'
              ></i>
            </label>
            <ImageUploading
              multiple
              value={imagesssAdd}
              onChange={onChangessAdd}
              maxNumber={maxNumberss}
              dataURLKey='data_url'
              acceptType={['png', 'jpg', 'jpeg', 'svg']}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className='upload__image-wrapper'>
                  <button
                    className='btn btn-secondary'
                    style={isDragging ? {color: 'red'} : {}}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Choose image
                  </button>
                  &nbsp;
                  <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                    Remove all images
                  </button>
                  {imageList.map((image, index) => (
                    <div key={index} className='image-item'>
                      <img src={image.data_url} alt='' width='100' />
                      <div className='image-item__btn-wrapper'>
                        <button className='btn btn-secondary' onClick={() => onImageUpdate(index)}>
                          Update
                        </button>
                        <button className='btn btn-secondary' onClick={() => onImageRemove(index)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
          <div className='text-center pt-15'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-kt-permissions-modal-action='cancel'
              onClick={() => {
                handleCloseprojectDetailsEditBoxImageAdd()
              }}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => {
                addmoreimage()
              }}
              data-kt-permissions-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* </form> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDelete}>
            No
          </Button>
          <Button variant='primary' onClick={hendleDelete}>
            Yes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <h3> Details</h3>
          <div className='mb-10 '>
            <div>
              <div className='row'>
                <div className='col-3'>
                  <h6> Project Name : - </h6>
                </div>
                <div className='col-3'>
                  {' '}
                  <p>{get_project_details?.projects?.title}</p>
                </div>
              </div>

              <div className='d-flex'>
                <div className='col-3'>
                  <h6>platform</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.projects?.platform}</p>
                </div>
              </div>

              <div className='row'>
                <div className='col-3'>
                  <h6> meta title : - </h6>
                </div>
                <div className='col-3'>
                  {' '}
                  <p>{get_project_details?.projects?.meta_title}</p>
                </div>
              </div>

              <div className='row'>
                <div className='col-3'>
                  <h6> meta description : - </h6>
                </div>
                <div className='col-3'>
                  {' '}
                  <p>{get_project_details?.projects?.meta_description}</p>
                </div>
              </div>

              <div className='row'>
                <div className='col-3'>
                  <h6> Short Description description : - </h6>
                </div>
                <div className='col-3'>
                  {' '}
                  <p>{get_project_details?.projects?.description}</p>
                </div>
              </div>

              <h6>Image :-</h6>
              <div className='productDetailsImg'>
                <img src={URL.API_BASE_URL + get_project_details?.projects?.featureimage} alt='' />
              </div>
              <div>
                {/* <h6>Teg</h6>
                      <p>{roleID?.tegs}</p> */}
              </div>

              <hr />

              <h3>Full Details</h3>

              <button
                data-id='2209'
                onClick={() => {
                  hendleToEditProjectDetails()
                }}
                className='btn btn-sm btn-info ms-3'
              >
                <i className='fas fa-edit'></i>
              </button>

              <div className='d-flex'>
                <div className='col-3'>
                  <h6>demousername</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.demousername}</p>
                </div>
              </div>

              <div className='d-flex'>
                <div className='col-3'>
                  <h6>clientName</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.clientName}</p>
                </div>
              </div>

              <div className='d-flex'>
                <div className='col-3'>
                  <h6>islive</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.islive}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>slug</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.slug}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>versionname</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.versionname}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>webLink</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.webLink}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>demopin</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.demopin}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>demopassword</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.demopassword}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>startAt</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.startAt}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>deliverAt</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.deliverAt}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>appstoreLink</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.appstoreLink}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>adminLink</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.adminLink}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>versioncode</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  <p>{get_project_details?.versioncode}</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='col-3'>
                  <h6>Description</h6>
                </div>
                <div className='col-9'>
                  {' '}
                  {/* <p>{get_project_details?.versioncode}</p> */}
                  <div dangerouslySetInnerHTML={{__html: get_project_details?.editorContent}} />
                </div>
              </div>

              {/* <h6>
                    <p>{get_project_details?.projects?.title}</p>
                    </h6> */}

              <h6>Project Image</h6>
              <div className='row'>
                {get_project_details?.images &&
                  get_project_details?.images?.map((data: any, i: any) => {
                    console.log(data)

                    return (
                      <div className='col-3 proMultiImg '>
                        <div className='proMultiImgMAin'>
                          <img src={URL.API_BASE_URL + data?.image} alt='' />
                          <div
                            className='editimagicon'
                            onClick={() => {
                              hendleToseditImage(data)
                            }}
                          >
                            <i className='fas fa-pencil text-dark'></i>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>

              <div>
                <button
                  className='btn btn-sm btn-info ms-3'
                  onClick={() => {
                    hendleTonewPhoto()
                  }}
                >
                  Add New Image
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails
