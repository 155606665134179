import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import { 
  LOADING,

  GET_PERMISSION_LISTS,
  GET_ROLES_LISTS,
  GET_PRODUCT_LISTS,
  CREATE_PRODUCT,
  GET_CATEGORY_LISTS,
  GET_SELLER_LISTS,
  GET_SUB_SUB_CATEGORY_LISTS,
  GET_SELLER_LISTS_PRO,
  GET_CATEGORY_ALL_LISTS,
  GET_SUB_CATEGORY_LISTS,
  GET_SUB_SUB_CATE_LISTS,
  GET_SUB_CATEGORY_ALL_LISTS,
  GET_PACKAGE_LISTS,
  GET_TRANSACTION_LISTS,
  GET_TICKET_LISTS,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_SELLER_PROFILE,
  GET_PAGES,
  GET_WHATSAPP_QU,
  GET_SUBSCRIPTION,
  GET_ADMIN_PROFILE,
  GET_INVOICE_DETAILS,
  GET_PACKAGE_LISTS_BUY,
  GET_EMAIL_QU,
  CREATE_TAGS,
  SEARCH_TAGS,
  FOOTER_PAGE_TYPE,
FOOTER_PAGE_LIST,
FOOTER_PAGE_LIST_SELECT,
ANALYTICS_PRO_DATE,
TOP_VIEW_PRO,
TOP_VIEW_PRO_BY_CATE,
TOP_VIEW_PRO_BY_SELLER,


CREATE_SERVICES,
CREATE_SERVICES1,
GET_ALL_SERVICES,
GET_PRODUCT_BY_PAGI,
CREATE_PROJECT,
GET_PROJECT_DETAILS,
CREATE_PROJECT_DATA,
DELETE_PROJECT,
GET_SETTING,
 
 } from "../common/constant";

const loading = (data:any) => {
  return { type: LOADING, payload: data };
};

const getperListAction = (data:any) => {
  return { type: GET_PERMISSION_LISTS, payload: data };
};
const getroleListAction = (data:any) => {
  return { type: GET_ROLES_LISTS, payload: data };
};
const getproductListAction = (data:any) => {
  return { type: GET_PRODUCT_LISTS, payload: data };
};
const getpackageListAction = (data:any) => {
  return { type: GET_PACKAGE_LISTS, payload: data };
};
const getcategoryListAction = (data:any) => {
  return { type: GET_CATEGORY_LISTS, payload: data };
};
const getsubcategoryListAction = (data:any) => {
  return { type: GET_SUB_CATEGORY_LISTS, payload: data };
};
const getsubsubcategoryListAction = (data:any) => {
  return { type: GET_SUB_SUB_CATEGORY_LISTS, payload: data };
};
const getsubsubcateListAction = (data:any) => {
  return { type: GET_SUB_SUB_CATE_LISTS, payload: data };
};
const getsellerListAction = (data:any) => {
  return { type: GET_SELLER_LISTS, payload: data };
};
const getsellerListProAction = (data:any) => {
  return { type: GET_SELLER_LISTS_PRO, payload: data };
};
const getscateAllListAction = (data:any) => {
  return { type: GET_CATEGORY_ALL_LISTS, payload: data };
};
const getstransationListAction = (data:any) => {
  return { type: GET_TRANSACTION_LISTS, payload: data };
};
const getssubcateAllListAction = (data:any) => {
  return { type: GET_SUB_CATEGORY_ALL_LISTS, payload: data };
};
const getticketListAction = (data:any) => {
  return { type: GET_TICKET_LISTS, payload: data };
};
const getMessageListAction = (data:any) => {
  return { type: GET_MESSAGE_LISTS, payload: data };
};
const getInvoiceAction = (data:any) => {
  return { type: GET_INVOICE, payload: data };
};
const getSellerProfileAction = (data:any) => {
  return { type: GET_SELLER_PROFILE, payload: data };
};
const getPagesAction = (data:any) => {
  return { type: GET_PAGES, payload: data };
};
const getwhatsappQuAction = (data:any) => {
  return { type: GET_WHATSAPP_QU, payload: data };
};
const getsubscrptionAction = (data:any) => {
  return { type: GET_SUBSCRIPTION, payload: data };
};
const getAdminProfilenAction = (data:any) => {
  return { type: GET_ADMIN_PROFILE, payload: data };
};
const getinvoiceDetailsAction = (data:any) => {
  return { type: GET_INVOICE_DETAILS, payload: data };
};
const getpackageByAction = (data:any) => {
  return { type: GET_PACKAGE_LISTS_BUY, payload: data };
};
const getemailquAction = (data:any) => {
  return { type: GET_EMAIL_QU, payload: data };
};
const gettagssAction = (data:any) => {
  return { type: CREATE_TAGS, payload: data };
};
const getsearchtagssAction = (data:any) => {
  return { type: SEARCH_TAGS, payload: data };
};
const footerPageTypeAction = (data:any) => {
  return { type: FOOTER_PAGE_TYPE, payload: data };
};
const FooterPageListAction = (data:any) => {
  return { type: FOOTER_PAGE_LIST, payload: data };
};
const FooterPageListSelectAction = (data:any) => {
  return { type: FOOTER_PAGE_LIST_SELECT, payload: data };
};
const productdateWiseAction = (data:any) => {
  return { type: ANALYTICS_PRO_DATE, payload: data };
};
const topViewproductAction = (data:any) => {
  return { type: TOP_VIEW_PRO, payload: data };
};
const topViewproductBycateAction = (data:any) => {
  return { type: TOP_VIEW_PRO_BY_CATE, payload: data };
};
const topViewproductBySellerAction = (data:any) => {
  return { type: TOP_VIEW_PRO_BY_SELLER, payload: data };
};


const createServicesAction = (data:any) => {
  return { type: CREATE_SERVICES, payload: data };
};
const createServices1Action = (data:any) => {
  return { type: CREATE_SERVICES1, payload: data };
};
const getAllServicesAction = (data:any) => {
  return { type: GET_ALL_SERVICES, payload: data };
};
const getproductbypagiAction = (data:any) => {
  return { type: GET_PRODUCT_BY_PAGI, payload: data };
};
const createProjectAction = (data:any) => {
  return { type: CREATE_PROJECT, payload: data };
};
const createProject2Action = (data:any) => {
  return { type: CREATE_PROJECT_DATA, payload: data };
};
const getProjectDetailsAction = (data:any) => {
  return { type: GET_PROJECT_DETAILS, payload: data };
};
const deleteProAction = (data:any) => {
  return { type: DELETE_PROJECT, payload: data };
};
const getSettingAction = (data:any) => {
  return { type: GET_SETTING, payload: data };
};



// const
// fineUniversityBySlugAction = (data:any) => {
//   return { type: GET_UNIVERSITIES_BY_SLUG, payload: data };
// };
export const getSettings = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getSettings}`, );
          dispatch(getSettingAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getProjectsDetails = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getProjectsDetails+data}`, );
          dispatch(getProjectDetailsAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteProjectImage = (data:any,id:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };


      // deleteProjectImage

      const response:any = await api.delete(`${URL.deleteProjectImage+data}`, );

     
      if (response?.data?.status) {
        // dispatch(createProjectAction(response?.data?.data));
        toast.success(response?.data?.message)

        const responsedata:any = await api.get(`${URL.getProjectsDetails+id}`, );
        dispatch(getProjectDetailsAction(responsedata?.data?.data));
        dispatch(loading(false));
 
      } else {
        toast.error(response?.data?.message)
      }


      // dispatch(loading(true));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateProjectImage = (data:any,id:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };


      // updateProjectImage

      const response:any = await api.post(`${URL.updateProjectImage}`,data );

     
      if (response?.data?.status) {
        // dispatch(createProjectAction(response?.data?.data));
        toast.success(response?.data?.message)

        const responsedata:any = await api.get(`${URL.getProjectsDetails+id}`, );
        dispatch(getProjectDetailsAction(responsedata?.data?.data));
        dispatch(loading(false));
 
      } else {
        toast.error(response?.data?.message)
      }


      // dispatch(loading(true));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createProject = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createProject}`,data );

     
      if (response?.data?.status) {
        dispatch(createProjectAction(response?.data?.data));
        toast.success(response?.data?.message)
      } else {
        toast.error(response?.data?.message)
      }
          
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const addProjectDetails = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.addProjectDetails}`,data );
   
console.log(response);

      if (response?.data?.status) {
        dispatch(createProject2Action(response?.data?.data));
        toast.success(response?.data?.message)
      } else {
        toast.error(response?.data?.message)
      }


       



          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateProject = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateProject}`,data );
          // dispatch(createProjectAction(response?.data?.data));


          if (response?.data?.status) {
            toast.success(response?.data?.message)
          } else {
            toast.error(response?.data?.message)
          }
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateProjectDetails = (data:any ,ProID:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateProjectDetails}`,data );
          // dispatch(createProjectAction(response?.data?.data));
// console.log(data?.projectid);
// console.log(data);


          if (response?.data?.status) {
            toast.success(response?.data?.message)

            const responsesss:any = await api.get(`${URL.getProjectsDetails+ProID}`, );
            dispatch(getProjectDetailsAction(responsesss?.data?.data));
          } else {
            toast.error(response?.data?.message)
          }
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getProjectsPaginate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getProjectsPaginate+data}`, );
          dispatch(getproductbypagiAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const createService = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createService}`,data );
if (response?.data?.status == true) {
  

  const responses:any = await api.get(`${URL.getServicesPaginate+1}`, );
  dispatch(createServicesAction(responses?.data?.data));
  // dispatch(createServices1Action(response?.data?.data));
}


          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteService = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.delete(`${URL.deleteService+data}`, );
          // dispatch(createServices1Action(response?.data?.data));


          if (response?.data?.status) {
            const response2:any = await api.get(`${URL.getServicesPaginate+1}`, );
            toast.success(response?.data?.message)
            dispatch(createServicesAction(response2?.data?.data));
          } else {
            
          }


          
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const deleteProject = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));


      // productID, pageNo
      const response:any = await api.delete(`${URL.deleteProject+data?.productID}`, );
          // dispatch(createServices1Action(response?.data?.data));


          if (response?.data?.status) {
            // const response2:any = await api.get(`${URL.getServices}`, );

            const response2:any = await api.get(`${URL.getProjectsPaginate+data?.pageNo}`, );
            toast.success(response?.data?.message)
            dispatch(getproductbypagiAction(response2?.data?.data));
          // dispatch(deleteProAction(response?.data?.data));
          } else {
            
          }


          
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getServicesPaginate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getServicesPaginate+data}`, );
          dispatch(createServicesAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getServices = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getServices}`, );
          dispatch(getAllServicesAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const topViewproductBySeller = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.topViewproductBySeller}` );
          dispatch(topViewproductBySellerAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const topViewproductBycate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.topViewproductBycate}` );
          dispatch(topViewproductBycateAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const topViewproduct = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.topViewproduct}` );
          dispatch(topViewproductAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const productdateWise = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.productdateWise}` );
          dispatch(productdateWiseAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const productYearWise = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.productYearWise}` );
          dispatch(productdateWiseAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const createFooterPageType = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createFooterPageType}`,data );
          dispatch(footerPageTypeAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPageTypesUpdate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.footerPageTypesUpdate}`,data );
          dispatch(footerPageTypeAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const footerPageTypesDelete = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.footerPageTypesDelete}`,data );
          dispatch(footerPageTypeAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createFooterPageList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createFooterPageList}`,data );
          dispatch(FooterPageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const footerPageListsDelete = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.footerPageListsDelete}`,data );
          dispatch(FooterPageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const footerPagepageListet = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.footerPagepageListet}`, );
          dispatch(FooterPageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const footerPageTypes = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.footerPageTypes}`, );
          dispatch(footerPageTypeAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getpageListfooter = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getpageListfooter}`, );
          dispatch(FooterPageListSelectAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};





export const createTags = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createTags}`,data );
          dispatch(gettagssAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const searchtags = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchtags}`,data );
          dispatch(getsearchtagssAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletetags = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deletetags}`,data );
          dispatch(gettagssAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatetags = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updatetags}`,data );
          dispatch(gettagssAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const gettagsList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.gettagsList}/${data}` );
          dispatch(gettagssAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const deletePage = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deletePage}`,data );
      dispatch(getPagesAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getpageList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getpageList}/${data}` );
          dispatch(getPagesAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getEmailenquiry = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getEmailenquiry}/${data}` );
          dispatch(getemailquAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const searchEmailenquiry = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchEmailenquiry}`,data );
          dispatch(getemailquAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getPackageListbuy = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getPackageListbuy}`);
          dispatch(getpackageByAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getInvoceDetails = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getInvoceDetails}`);
          dispatch(getinvoiceDetailsAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const createPurchaseAdmin = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createPurchaseAdmin}`,data );
          // dispatch(getinvoiceDetailsAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateInvoiceProfile = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateInvoiceProfile}`,data );
          dispatch(getinvoiceDetailsAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateInvoiceLogo = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateInvoiceLogo}`,data );
          dispatch(getinvoiceDetailsAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateHomeLogo = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateHomeLogo}`,data );
          dispatch(getinvoiceDetailsAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createAllsettings = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createAllsettings}`,data );
          dispatch(getinvoiceDetailsAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createBussnissPro = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createBussnissPro}`,data );
          dispatch(getAdminProfilenAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateWhatsaapNumber = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateWhatsaapNumber}`,data );
          // dispatch(getAdminProfilenAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateBussLogo = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateBussLogo}`,data );
          dispatch(getAdminProfilenAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateBussProfile = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateBussProfile}`,data );
          dispatch(getAdminProfilenAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const verifyWhatsapp = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.verifyWhatsapp}`,data );
          dispatch(getAdminProfilenAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getPages = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getPages}`);
          // dispatch(getPagesAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getAdminBussProfile = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getAdminBussProfile}`);
          dispatch(getAdminProfilenAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerprofile = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.getsellerprofile}`,data );
          dispatch(getSellerProfileAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getInvoice = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getInvoice}` );
          dispatch(getInvoiceAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getPermissionsList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getPermissionsList}`,config );
          dispatch(getperListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getRoleList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getRoleList}` );
          dispatch(getroleListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getProductList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getProductList}/${data}` );
          dispatch(getproductListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getSubscription = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getSubscription}/${data}` );
          dispatch(getsubscrptionAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const searchSubscription = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchSubscription}`,data );
          dispatch(getsubscrptionAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getWhatsappinquire = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getWhatsappinquire}/${data}` );
          dispatch(getwhatsappQuAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const searchWhatsappinquire = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchWhatsappinquire}`,data );
          dispatch(getwhatsappQuAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const createRoles = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createRoles}`,data );
          dispatch(getroleListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createAbout = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createAbout}`,data );
          dispatch(getPagesAction(response?.data?.data));
          dispatch(loading(false));
          if (response?.data?.success) {
            toast.success(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createPrivacyplicys = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createPrivacyplicys}`,data );
          dispatch(getPagesAction(response?.data?.data));
          if (response?.data?.success) {
            toast.success(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createProduct = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createProduct}`,data );


      console.log(response);
      

      if (response?.data?.status) {
        dispatch(getproductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }
     
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const copyProduct = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.copyProduct}`,data );
      dispatch(getproductListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const updateProduct = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateProduct}`,data );
      dispatch(getproductListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createTickets = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createTickets}`,data );
      dispatch(getticketListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const searchTicket = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchTicket}`,data );
      dispatch(getticketListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getmessageSupportList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.getmessageSupportList}`,data );
      dispatch(getMessageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createMessage = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createMessage}`,data );
      dispatch(getMessageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerByAdmin = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createSellerByAdmin}`,data );
      dispatch(getsellerListAction(response?.data?.data));

if (response?.data?.success) {
  toast.success(response?.data?.message);
} else {
  toast.error(response?.data?.message);
}

      
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createPackage = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createPackage}`,data );
      dispatch(getpackageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const getPackageList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getPackageList}?page=${data}` );
          dispatch(getpackageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getTicketList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getTicketList}/${data}` );
      dispatch(getticketListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchPackage = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchPackage}`,data );
      dispatch(getpackageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const deletePackage = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deletePackage}`,data );
      dispatch(getpackageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatePackage = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updatePackage}`,data );
      dispatch(getpackageListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const searchProduct = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchProduct}`,data );
      dispatch(getproductListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const deleteProduct = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deleteProduct}`,data );
      dispatch(getproductListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const statusProduct = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.statusProduct}`,data );
      dispatch(getproductListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};





export const createCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.createCategory}`,data );

      if (response?.data?.status) {
        toast.success(response?.data?.message)
        dispatch(getcategoryListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message)
      }
    
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateperentCate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateperentCate}`,data );
      dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const subcreateCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.subcreateCategory}`,data );
      dispatch(getsubcategoryListAction(response?.data?.data));
      // dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateperentsubCate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateperentsubCate}`,data );
      dispatch(getsubcategoryListAction(response?.data?.data));
      // dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const subsubcreateCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.subsubcreateCategory}`,data );
      dispatch(getsubsubcateListAction(response?.data?.data));
      // dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateperentsubsubCate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updateperentsubsubCate}`,data );
      dispatch(getsubsubcateListAction(response?.data?.data));
      // dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const getCategoryList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getCategoryList}/${data}` );
          dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const getsubCategoryList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getsubCategoryList}/${data}` );
          dispatch(getsubcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubCategoryList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getsubsubCategoryList}/${data}` );
          dispatch(getsubsubcateListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcate = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getsubsubcate}` );
          dispatch(getsubsubcategoryListAction(response?.data?.data));
          // toast.success(response?.data?.message);
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getSellerListforpro = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getSellerListforpro}` );
          dispatch(getsellerListProAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getcateAllList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getcateAllList}` );
          dispatch(getscateAllListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubcateAllList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getsubcateAllList}` );
          dispatch(getssubcateAllListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const deletesubsubCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deletesubsubCategory}`,data );
      dispatch(getsubsubcateListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesubCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deletesubCategory}`,data );
      dispatch(getsubcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deleteCategory}`,data );
      dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const statusCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.statusCategory}`,data );
      dispatch(getcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const statusSubCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.statusSubCategory}`,data );
      dispatch(getsubcategoryListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const statusSubSubCategory = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.statusSubSubCategory}`,data );
      dispatch(getsubsubcateListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};




export const getSellerList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getSellerList}/${data}` );
          dispatch(getsellerListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatesellerByAdmin = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.updatesellerByAdmin}` ,data);
          dispatch(getsellerListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};


export const deleteseller = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.deleteseller}`,data );
      dispatch(getsellerListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const statusSeller = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      // console.log(typeof token);
      // console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.statusSeller}`,data );
      dispatch(getsellerListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};



export const getTransactionList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      console.log(typeof token);
      console.log(JSON.parse(token));
      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.get(`${URL.getTransactionList}/${data}` );
          dispatch(getstransationListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchtransaction = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.searchtransaction}`,data );
      dispatch(getstransationListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getUpdateTransactionList = (data:any) => {
  return async (dispatch:any) => {
    try {
      const token:any = await localStorage.getItem("kt-auth-react-v");


      
      const config = {
        headers: {
          Authorization: "Bearer " + JSON.parse(token).api_token,
        },
      };
      dispatch(loading(true));
      const response:any = await api.post(`${URL.getUpdateTransactionList}`,data );
      dispatch(getstransationListAction(response?.data?.data));
          dispatch(loading(false));
    }
    catch(error:any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};