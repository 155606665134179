import React, {useEffect, useMemo, useState} from 'react'
import ReactPaginate from 'react-paginate'
import {useDispatch, useSelector} from 'react-redux'
import {adminAction} from '../../../redux/common/action'
import {Pagination, TableHeader} from '../Table'
import PaginationComponent from '../Table/Pagination/Pagination'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import Dropdown from 'react-bootstrap/Dropdown'
// @ts-ignore
import DatePicker from 'react-datepicker'
import moment from 'moment'

// modal
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {URL} from '../../../redux/common/url'
import {KTSVG} from '../../../_metronic/helpers'
// @ts-ignore
// import CKEditor from '@ckeditor/ckeditor5-react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
// @ts-ignore
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor'

// image uploader
import ImageUploading from 'react-images-uploading'
import {toast} from 'react-toastify'
import Select from 'react-select'

const loginSchema = Yup.object().shape({
  role_name: Yup.string()
    // .role_name('Wrong role_name format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('product is required'),
  role_description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('tegs is required'),
})

const initialValues = {
  role_name: '',
  role_description: '',
}

function Project() {
  const [sortingField, setSortingField] = useState('')
  const [sortingOrder, setSortingOrder] = useState('asc')
  // const [comments, setComments] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(1)

  const [itemsPerPage, setitemsPerPage] = useState(15)

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState({field: '', order: ''})

  const [boxShow, setboxShow] = useState('0')

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const page: any = searchParams.get('page')

  console.log(page)

  // deta table  state end

  const navigate = useNavigate()

  // modal

  const [show, setShow] = useState(false)

  const [addpermission, setaddpermission] = useState(false)
  const [showDelelt, setShowDelete] = useState(false)

  const [showUpdate, setShowUpdate] = useState(false)
  const handleCloseUpdate = () => setShowUpdate(false)
  const handleClose = () => setShow(false)

  const handleCloseaddpermission = () => setaddpermission(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShow = () => setShow(true)

  // modal end

  // api call

  const dispatch = useDispatch()
  const [roledata, setroledata] = useState<any>({
    role_name: '',
    role_description: '',
    title: '',
    description: '',
    slug: '',
  })

  const [loading, setLoading] = useState(false)
  const [roleID, setroleID] = useState<any>('')
  const [productID, setproductID] = useState<any>('')
  const [pageNo, setpageNo] = useState<any>(1)

  // get api data

  // image uploder

  const [images, setImages] = React.useState<any>([])
  const maxNumber = 1

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit

    setImages(imageList)
  }
  const [imagesss, setImagesss] = React.useState([])
  const maxNumberss = 8

  const onChangess = (imageList: any, addUpdateIndex: any) => {
    // data for submit

    setImagesss(imageList)
  }

  // console.log(images)

  const get_all_services: any[] = useSelector((state: any) =>
    state.admin.get_all_services ? state.admin.get_all_services : []
  )
  const create_project: any[] = useSelector((state: any) =>
    state.admin.create_project ? state.admin.create_project : {}
  )
  const get_product_list: any = useSelector((state: any) =>
    state.admin.get_project_by_pagi ? state.admin.get_project_by_pagi : {}
  )
  const get_project_details: any = useSelector((state: any) =>
    state.admin.get_project_details ? state.admin.get_project_details : {}
  )
  const get_project_data: any = useSelector((state: any) =>
    state.admin.get_project_data ? state.admin.get_project_data : {}
  )

  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedOption1, setSelectedOption1] = useState<any>(null)
  const [subList, setsubList] = React.useState<any>([])

  const ITEMS_PER_PAGE = 15

  const hendleToaddPer = (e: any) => {
    setroleID(e)
    // setboxShow('2')
    // setaddpermission(true)

    navigate('/project-details/' + e)
    // getProjectsDetails
    // dispatch(adminAction.getProjectsDetails(e))

    return () => {}
  }

  const hendleModalShow = (e: any) => {
    setShowDelete(true)
    setproductID(e)
  }

  useEffect(() => {
    const dropList = get_all_services?.map((data: any, i: any) => {
      data.value = data?.id
      data.label = data?.name

      return data
    })

    setsubList(dropList)
  }, [get_all_services])

  // useEffect(() => {
  //   const getData = () => {
  //     // showLoader();

  //     fetch('https://jsonplaceholder.typicode.com/comments')
  //       .then((response) => response.json())
  //       .then((json) => {
  //         // hideLoader();
  //         setComments(json)
  //         //   console.log(json)
  //       })
  //   }

  //   getData()
  // }, [])

  // console.log(get_product_list)

  const comments = get_product_list?.data ? get_product_list?.data : []

  const headers = [
    {name: 'S.No.', field: '_id', sortable: false},
    // {name: 'Name', field: 'name', sortable: true},
    {name: 'Image ', field: 'image', sortable: false},
    {name: 'Title ', field: 'title', sortable: false},
    {name: 'Plat form ', field: 'platform', sortable: false},
    // {name: 'Status', field: 'is_status', sortable: false},
    // {name: 'Deleted', field: 'delete', sortable: false},
    {name: 'Action', field: 'action', sortable: false},
  ]

  const commentsData = useMemo(() => {
    let computedComments = comments

    // if (search) {
    //     computedComments = computedComments.filter(
    //         comment =>
    //             comment.name.toLowerCase().includes(search.toLowerCase()) ||
    //             comment.role_name.toLowerCase().includes(search.toLowerCase())
    //     );
    // }

    setTotalItems(computedComments.length)

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1
      computedComments = computedComments.sort(
        (a: any, b: any) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      )
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    )
  }, [get_product_list, currentPage, search, sorting])

  // const handlePageClick = (event: any) => {
  //   const newOffset = event.selected % (comments.length / 2)
  //   setCurrentPage(newOffset + 1)
  //   setItemOffset(newOffset * itemsPerPage)
  // }

  const handlePageClick = (event: any) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    navigate('/project?page=' + data)

    // if (!userSearch) {
    // dispatch(adminAction.getProjectsPaginate(data))

    return () => {}
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  }

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage

    setPageCount(Math.ceil(get_product_list?.total / itemsPerPage))
  }, [itemOffset, itemsPerPage, comments])

  // Table shoorting Function

  const onSortingChange = (field: any) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    setSorting({field, order})
  }

  const hendleChangerole = (e: any) => {
    const {name, value} = e.target
    setroledata({...roledata, [name]: value})
  }

  const [aboutPage, setaboutPage] = useState<any>('')

  const hendleSubmitCreate = () => {
    setLoading(true)
    try {
      // const {data: auth} = await login(values.role_name, values.role_description)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData()

      // data.append("title", imageEditfor);

      // images

      if (images?.length == 0) {
        toast.error('please select feature Image')
      } /* else if (images?.length == 0) {
        toast.error('please select  Image')
      } */ else if (!roledata?.platform) {
        toast.error('please enter  platform')
      } else if (!roledata?.title) {
        toast.error('please enter  title')
      } else if (!roledata?.description) {
        toast.error('please enter  description')
      } else if (!selectedOption) {
        toast.error('please select service')
      } else {
        data.append('title', roledata?.title)
        data.append('description', roledata?.description)
        //   data.append('platform', roledata?.role_name)

        if (roledata?.slug) {
          data.append('slug', roledata?.slug)
        }
        data.append('platform', roledata?.platform)
        // data.append('categoryName', 'test cate')
        data.append('serviceid', selectedOption?.id)
        data.append('featureimage', images[0]?.file)
        // imagesss?.forEach((file: any) => {
        //   console.log(file?.file)
        //   data.append('images', file?.file)
        // })

        dispatch(adminAction.createProject(data))
        setShow(false)

        setroledata({role_name: '', role_description: '', title: '', description: '', slug: ''})
        setLoading(false)
      }
      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const {data: auth} = await login(values.role_name, values.role_description)
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // dispatch(adminAction.createRoles(values))
        const data = new FormData()

        // data.append("title", imageEditfor);

        // images

        data.append('name', values?.role_name)
        data.append('description', values?.role_description)
        data.append('categoryName', 'test cate')
        data.append('parentId', '11')
        data.append('sellerId', '20')

        data.append('image', images[0]?.file)
        // imagesss?.forEach((file: any) => {
        //   console.log(file?.file)
        //   data.append('images', file?.file)
        // })

        dispatch(adminAction.createCategory(data))
        setShow(false)
        setLoading(false)
        // setCurrentUser(user)
      } catch (error) {
        // saveAuth(undefined)
        // setStatus('The login details are incorrect')
        // setSubmitting(false)
        // setLoading(false)
      }
    },
  })

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    dispatch(adminAction.getProjectsPaginate(page == null ? 1 : page))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }, [create_project, page, get_project_data])
  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    dispatch(adminAction.getServices(1))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }, [])

  // create product

  // createProduct

  const hendleCreateProduct = () => {
    const data = new FormData()

    // data.append("title", imageEditfor);

    // images

    imagesss?.forEach((file) => {})

    //
  }

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false)
    dispatch(adminAction.deleteProject({productID, pageNo}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }

  const hendleStatusUpdate = (id: any, status: any) => {
    // statusProduct
    dispatch(adminAction.statusCategory({productId: id, status: status}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {}
  }

  const API_URl = URL.API_BASE_URL
  const UPLOAD_ENDPOINT = 'publicApi/productcon'

  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file.then((file: any) => {
            body.append('upload', file)
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: 'post',
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({default: `${res.url}`})
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    }
  }
  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader)
    }
  }

  const [roledataUpdate, setroledataUpdate] = useState<any>({
    name: '',
    role_description: '',
    meta_title: '',
    meta_description: '',
    cate_id: '',
    slug: '',
  })
  const [roledataUpdate2, setroledataUpdate2] = useState<any>({
    name: '',
    role_description: '',
    meta_title: '',
    meta_description: '',
    cate_id: '',
    slug: '',
  })

  const hendleChangerole2 = (e: any) => {
    const {name, value} = e.target
    setroledataUpdate({...roledataUpdate, [name]: value})
  }

  const [categoryUpPage, setcategoryUpPage] = useState<any>('')
  const [Imagesfe, setImagesfe] = useState<any>('')
  const onChangefe = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImagesfe(imageList)
  }

  const hendleEditPage = (e: any) => {
    setShowUpdate(true)

    setroledataUpdate({
      // name: e.name,
      title: e.title,
      platform: e.platform,
      slug: e.slug,
      description: e.description,
      cate_id: e.id,
    })
    setroledataUpdate2({
      // name: e.name,
      title: e.title,
      platform: e.platform,
      slug: e.slug,
      description: e.description,
      cate_id: e.id,
    })

    // setSelectedOption1

    // subList

    const selectCate = subList.find((edata: any) => edata?.id == e?.serviceid)

    setSelectedOption1(selectCate)

    setcategoryUpPage(e.description)
    // console.log(URL.API_BASE_URL + e.featureimage)

    setImagesfe([{data_url: URL.API_BASE_URL + e.featureimage}])
  }

  // updateperentCate

  const hendleSubmitupdateperentCate = () => {
    setLoading(true)
    try {
      // const {data: auth} = await login(values.role_name, values.role_description)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData()

      // data.append("title", imageEditfor);

      // images

      if (Imagesfe?.length == 0) {
        toast.error('please select feature Image')
      } /* else if (images?.length == 0) {
        toast.error('please select  Image')
      } */ else if (!roledataUpdate?.title) {
        toast.error('please enter title')
      } else if (!roledataUpdate?.platform) {
        toast.error('please enter platform')
      } else if (!roledataUpdate?.description) {
        toast.error('please enter description')
      } else if (!selectedOption1) {
        toast.error('please select services ')
      } else {
        {
          /* title
    platform
    slug
    description */
        }
        data.append('title', roledataUpdate?.title)
        data.append('platform', roledataUpdate?.platform)
        data.append('description', roledataUpdate?.description)
        // data.append('description', categoryUpPage)
        data.append('serviceid', selectedOption1?.id)
        // data.append('meta_title', roledataUpdate?.meta_title)
        // data.append('meta_description', roledataUpdate?.meta_description)
        data.append('id', roledataUpdate?.cate_id)

        if (roledataUpdate2?.slug !== roledataUpdate?.slug) {
          if (roledataUpdate?.slug) {
            data.append('slug', roledataUpdate?.slug)
          }
        }

        // data.append('categoryName', 'test cate')
        // data.append('pageNo', pageNo)
        // data.append('parentId', '11')
        // data.append('sellerId', '20')

        if (Imagesfe[0]?.file) {
          // roledata?.role_name
          data.append('featureimage', Imagesfe[0]?.file)
        }

        dispatch(adminAction.updateProject(data))
        setShowUpdate(false)
        setLoading(false)
      }
      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())
  const [projectId, setprojectId] = useState('')
  const [projectIdDetalis, setprojectIdDetalis] = useState<any>({slug: ''})

  const hendleToAdd = (e: any) => {
    const {name, value} = e.target
    setprojectIdDetalis({...projectIdDetalis, [name]: value})
  }

  const [showAddProject, setshowAddProject] = useState(false)

  //   const handleShow = () => setShow(true)

  const hendleCloseAddPRo = () => setshowAddProject(false)

  const hendleToAddDetails = () => {
    // addProjectDetails
    try {
      // const {data: auth} = await login(values.role_name, values.role_description)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData()

      // data.append("title", imageEditfor);

      // images

      //   if (imagesss?.length == 0) {
      //     toast.error('please select feature Image')
      //   } /* else if (images?.length == 0) {
      //       toast.error('please select  Image')
      //     } */ else

      if (!projectIdDetalis?.clientName) {
        toast.error('please enter clint name')
      } /*  else if (!projectIdDetalis?.title) {
        toast.error('please enter  title')
      } else if (!projectIdDetalis?.description) {
        toast.error('please enter  description')
      } else if (!selectedOption) {
        toast.error('please select service')
      } */ else {
        // data.append('title', projectIdDetalis?.title)
        data.append('longDescription', projectIdDetalis?.longDescription)

        data.append('editorContent', aboutPage)
        data.append('clientName', projectIdDetalis?.clientName)

        if (projectIdDetalis?.slug) {
          data.append('slug', projectIdDetalis?.slug)
        }

        data.append('appstoreLink', projectIdDetalis?.appstoreLink)
        data.append('playstoreLink', projectIdDetalis?.playstoreLink)
        data.append('webLink', projectIdDetalis?.webLink)
        data.append('adminLink', projectIdDetalis?.adminLink)
        data.append('demousername', projectIdDetalis?.demousername)
        data.append('demopassword', projectIdDetalis?.demopassword)
        data.append('demopin', projectIdDetalis?.demopin)
        data.append('versionname', projectIdDetalis?.versionname)
        data.append('projectid', projectId)
        data.append('startAt', moment(startDate).format('YYYY-MM-DD'))
        data.append('deliverAt', moment(endDate).format('YYYY-MM-DD'))

        //
        // data.append('editorContent', projectIdDetalis?.projectid)

        data.append('serviceid', selectedOption?.id)
        //   data.append('featureimage', images[0]?.file)
        imagesss?.forEach((file: any, i: any) => {
          data.append('image[' + i + ']', file?.file)
        })

        dispatch(adminAction.addProjectDetails(data))
        // setShow(false)
        // setshowAddProject(false)
        setboxShow('0')
        setaboutPage('')

        setprojectIdDetalis({slug: ''})
        setLoading(false)
      }
      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const hendleToDetailModal = (e: any) => {
    setprojectId(e)
    setboxShow('1')
    window.scrollTo(50, 0)
  }

  // console.log(get_project_details)

  return (
    <div>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      {/* <!--begin::Content wrapper-->  */}
      <div className='d-flex flex-column flex-column-fluid'>
        {/* <!--begin::Toolbar-->  */}
        <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          {/* <!--begin::Toolbar container-->  */}
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Project</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Woohoo, you're reading this text in a modal! */}
                {/* <form
                  id='kt_modal_update_permission_form'
                  className='form'
                  action='#'
                  onSubmit={formik.handleSubmit}
                  noValidate
                > */}
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Image</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <ImageUploading
                    // multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey='data_url'
                    acceptType={['png',"jpg", "jpeg", "svg"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className='upload__image-wrapper'>
                        <button
                          style={isDragging ? {color: 'red'} : {}}
                          onClick={onImageUpload}
                          {...dragProps}
                          className='btn btn-secondary'
                        >
                          Choose image
                        </button>
                        &nbsp;
                        <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item'>
                            <img src={image.data_url} alt='' width='100' />
                            <div className='image-item__btn-wrapper'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Service</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>

                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={subList}
                  />
                </div>

                {/* <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>description</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a role name'
                      // name='role_name'
                      {...formik.getFieldProps('role_description')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.role_description && formik.errors.role_description,
                        },
                        {
                          'is-valid':
                            formik.touched.role_description && !formik.errors.role_description,
                        }
                      )}
                    />
                    {formik.touched.role_description && formik.errors.role_description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_description}</span>
                      </div>
                    )}
                  </div> */}

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Title</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter description'
                    name='title'
                    onChange={(e: any) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Slug</span>
                    {/* <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i> */}
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter slug'
                    name='slug'
                    onChange={(e: any) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>platform</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  {/* <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a  platform'
                    name='platform'
                    onChange={(e: any) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx('form-control bg-transparent',
                      
                  /> */}
                 

                 <select className='form-control bg-transparent'   name='platform'
                    onChange={(e: any) => {
                      hendleChangerole(e)
                    }}>
                   <option value={""}>select platform</option>
                   <option value={"Android"}>Android</option>
                   <option value={"Website"}>Website</option>
                 </select>
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Description</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <textarea
                    // className='form-control form-control-solid'
                    placeholder='Enter description'
                    name='description'
                    onChange={(e: any) => {
                      hendleChangerole(e)
                    }}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>

                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-permissions-modal-action='cancel'
                    onClick={handleClose}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-permissions-modal-action='submit'
                    onClick={() => {
                      hendleSubmitCreate()
                    }}
                    // disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* </form> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* Create pproject detail */}

            <Modal show={showAddProject} onHide={hendleCloseAddPRo}>
              <Modal.Header closeButton>
                <Modal.Title>Add Prodct details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>clientName</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a clientName'
                    name='clientName'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>slug</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a slug'
                    name='slug'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>longDescription</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a longDescription'
                    name='longDescription'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>playstoreLink</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a playstoreLink'
                    name='playstoreLink'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>appstoreLink</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a appstoreLink'
                    name='appstoreLink'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                {/* <div className='form-group col-md-6'>
                  <label>Start Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => setStartDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label>deliver date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: any) => setEndDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control bg-transparent'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div> */}
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Start Dat</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => setStartDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>deliver date</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: any) => setEndDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control bg-transparent'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>webLink</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a webLink'
                    name='webLink'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>adminLink</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a adminLink'
                    name='adminLink'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>demousername</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a demousername'
                    name='demousername'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>demopassword</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a demopassword'
                    name='demopassword'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>demopin</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a demopin'
                    name='demopin'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>versioncode</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a versioncode'
                    name='versioncode'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>versionname</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter a versionname'
                    name='versionname'
                    onChange={(e) => {
                      hendleToAdd(e)
                    }}
                    // {...formik.getFieldProps('role_name')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                      {
                        'is-valid': formik.touched.role_name && !formik.errors.role_name,
                      }
                    )}
                    // onChange={(e:any)=>{hendleToAdd(e)}}
                  />
                  {formik.touched.role_name && formik.errors.role_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_name}</span>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Description</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <CKEditor
                    editor={Editor}
                    data={aboutPage}
                    onReady={(editor: any) => {}}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData()
                      setaboutPage(data)
                    }}
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    // config={{
                    //   // plugins: [ Essentials ],
                    //   ckfinder: {
                    //     // The URL that the images are uploaded to.
                    //     uploadUrl: `${URL.API_BASE_URL}/apiPublic/productcon`,

                    //     // Enable the XMLHttpRequest.withCredentials property.
                    //     withCredentials: true,

                    //     // Headers sent along with the XMLHttpRequest to the upload server.
                    //     headers: {
                    //       "X-CSRF-TOKEN": "CSFR-Token",
                    //       Authorization: "Bearer <JSON Web Token>",
                    //     },
                    //   },
                    // }}
                    onBlur={(event: any, editor: any) => {}}
                    onFocus={(event: any, editor: any) => {}}
                  />
                </div>

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Image</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <ImageUploading
                    multiple
                    value={imagesss}
                    onChange={onChangess}
                    maxNumber={maxNumberss}
                    dataURLKey='data_url'
                    acceptType={['png',"jpg", "jpeg", "svg"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className='upload__image-wrapper'>
                        <button
                          className='btn btn-secondary'
                          style={isDragging ? {color: 'red'} : {}}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Choose image
                        </button>
                        &nbsp;
                        <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item'>
                            <img src={image.data_url} alt='' width='100' />
                            <div className='image-item__btn-wrapper'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-permissions-modal-action='cancel'
                    onClick={hendleCloseAddPRo}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      hendleToAddDetails()
                    }}
                    data-kt-permissions-modal-action='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* </form> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* update */}
            <Modal show={showUpdate} onHide={handleCloseUpdate}>
              <Modal.Header closeButton>
                <Modal.Title>Update Project</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Woohoo, you're reading this text in a modal! */}
                {/* <form
                  id='kt_modal_update_permission_form'
                  className='form'
                  action='#'
                  onSubmit={formik.handleSubmit}
                  noValidate
                > */}
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Image</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <ImageUploading
                    // multiple
                    value={Imagesfe}
                    onChange={onChangefe}
                    maxNumber={maxNumber}
                    dataURLKey='data_url'
                    acceptType={['png',"jpg", "jpeg", "svg"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className='upload__image-wrapper'>
                        <button
                          style={isDragging ? {color: 'red'} : {}}
                          onClick={onImageUpload}
                          {...dragProps}
                          className='btn btn-secondary'
                        >
                          Choose image
                        </button>
                        &nbsp;
                        <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item'>
                            <img src={image.data_url} alt='' width='100' />
                            <div className='image-item__btn-wrapper'>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className='btn btn-secondary'
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Select Services</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>

                  <Select
                    defaultValue={selectedOption1}
                    onChange={setSelectedOption1}
                    options={subList}
                  />
                </div>
                {/* <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>description</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a role name'
                      // name='role_name'
                      {...formik.getFieldProps('role_description')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.role_description && formik.errors.role_description,
                        },
                        {
                          'is-valid':
                            formik.touched.role_description && !formik.errors.role_description,
                        }
                      )}
                    />
                    {formik.touched.role_description && formik.errors.role_description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_description}</span>
                      </div>
                    )}
                  </div> */}

                {/* title
    platform
    slug
    description */}

                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>title</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter title'
                    name='title'
                    onChange={(e: any) => {
                      hendleChangerole2(e)
                    }}
                    value={roledataUpdate?.title}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>slug</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <input
                    // className='form-control form-control-solid'
                    placeholder='Enter slug'
                    name='slug'
                    onChange={(e: any) => {
                      hendleChangerole2(e)
                    }}
                    value={roledataUpdate?.slug}
                    // {...formik.getFieldProps('role_description')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>platform</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  {/* <input
                    // className='form-control form-control-solid'
                    placeholder='Enter platform'
                    name='platform'
                    onChange={(e: any) => {
                      hendleChangerole2(e)
                    }}
                    value={roledataUpdate?.platform}
                    // {...formik.getFieldProps('role_description')}
                    className='form-control bg-transparent'
                      
                  /> */}
               

               <select className='form-control bg-transparent'    value={roledataUpdate?.platform}  name='platform'
                    onChange={(e: any) => {
                      hendleChangerole2(e)
                    }}>
                   <option value={""}>select platform</option>
                   <option value={"Android"}>Android</option>
                   <option value={"Website"}>Website</option>
                 </select>
                </div>
                <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'> description</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>
                  <textarea
                    // className='form-control form-control-solid'
                    placeholder='Enter description'
                    name='description'
                    onChange={(e: any) => {
                      hendleChangerole2(e)
                    }}
                    value={roledataUpdate?.description}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.role_description && formik.errors.role_description,
                      },
                      {
                        'is-valid':
                          formik.touched.role_description && !formik.errors.role_description,
                      }
                    )}
                  />
                  {formik.touched.role_description && formik.errors.role_description && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.role_description}</span>
                    </div>
                  )}
                </div>
                {/* <div className='fv-row mb-7'>
                  <label className='fs-6 fw-semibold form-label mb-2'>
                    <span className='required'>Description</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='popover'
                      data-bs-trigger='hover'
                      data-bs-html='true'
                      data-bs-content='Permission names is required to be unique.'
                    ></i>
                  </label>

                  <CKEditor
                    editor={Editor}
                    data={categoryUpPage}
                    onReady={(editor: any) => {}}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData()
                      setcategoryUpPage(data)
                    }}
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    // config={{
                    //   // plugins: [ Essentials ],
                    //   ckfinder: {
                    //     // The URL that the images are uploaded to.
                    //     uploadUrl: `${URL.API_BASE_URL}/apiPublic/productcon`,

                    //     // Enable the XMLHttpRequest.withCredentials property.
                    //     withCredentials: true,

                    //     // Headers sent along with the XMLHttpRequest to the upload server.
                    //     headers: {
                    //       "X-CSRF-TOKEN": "CSFR-Token",
                    //       Authorization: "Bearer <JSON Web Token>",
                    //     },
                    //   },
                    // }}
                    onBlur={(event: any, editor: any) => {
                      console.log('SDfsf')
                    }}
                    onFocus={(event: any, editor: any) => {
                      console.log('SDfsf')
                    }}
                  />
                </div> */}

                {/* <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Category</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <select className='form-select' aria-label='Select example'>
                      <option>Select Category</option>
                      <option value='1'>One</option>
                      <option value='2'>Two</option>
                      <option value='3'>Three</option>
                    </select>
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Select Seller</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <select className='form-select' aria-label='Select example'>
                      <option>Select Seller</option>
                      <option value='1'>One</option>
                      <option value='2'>Two</option>
                      <option value='3'>Three</option>
                    </select>
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Image</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <ImageUploading
                      multiple
                      value={imagesss}
                      onChange={onChangess}
                      maxNumber={maxNumberss}
                      dataURLKey='data_url'
                     acceptType={['png',"jpg", "jpeg", "svg"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className='upload__image-wrapper'>
                          <button
                            className='btn btn-secondary'
                            style={isDragging ? {color: 'red'} : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Choose image
                          </button>
                          &nbsp;
                          <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className='image-item'>
                              <img src={image.data_url} alt='' width='100' />
                              <div className='image-item__btn-wrapper'>
                                <button
                                  className='btn btn-secondary'
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className='btn btn-secondary'
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div> */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    className='btn btn-light me-3'
                    data-kt-permissions-modal-action='cancel'
                    onClick={handleCloseUpdate}
                  >
                    Discard
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-permissions-modal-action='submit'
                    onClick={() => {
                      hendleSubmitupdateperentCate()
                    }}
                    // disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* </form> */}
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* View modal */}
            <Modal show={addpermission} size='lg' onHide={handleCloseaddpermission}>
              <Modal.Header closeButton>
                <Modal.Title>Project Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Woohoo, you're reading this text in a modal! */}

                <div className='mb-10 '>
                  <div>
                    <div className='row'>
                      <div className='col-3'>
                        <h6> Project Name : - </h6>
                      </div>
                      <div className='col-3'>
                        {' '}
                        <p>{get_project_details?.projects?.title}</p>
                      </div>
                      {/* <div className='col-3'>
                          <h6> Project Name : - </h6>
                        </div>
                        <div className='col-3'>
                          {' '}
                          <p>{get_project_details?.projects?.title}</p>
                        </div> */}
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>clientName</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.clientName}</p>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>platform</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.platform}</p>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>demousername</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.demousername}</p>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>islive</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.islive}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>slug</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.slug}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>versionname</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.versionname}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>webLink</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.webLink}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>demopin</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.demopin}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>demopassword</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.demopassword}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>startAt</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.startAt}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>deliverAt</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.deliverAt}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>appstoreLink</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.appstoreLink}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>adminLink</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.adminLink}</p>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='col-3'>
                        <h6>versioncode</h6>
                      </div>
                      <div className='col-9'>
                        {' '}
                        <p>{get_project_details?.projects?.versioncode}</p>
                      </div>
                    </div>

                    {/* <h6>
                    <p>{get_project_details?.projects?.title}</p>
                    </h6> */}

                    <h6>Image :-</h6>
                    <div className='productDetailsImg'>
                      <img
                        src={URL.API_BASE_URL + get_project_details?.projects?.featureimage}
                        alt=''
                      />
                    </div>
                    <div>
                      {/* <h6>Teg</h6>
                      <p>{roleID?.tegs}</p> */}
                    </div>
                    {/* <h6>product Image</h6>
                    <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div> */}
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>

            {/* delete modal  */}
            <Modal show={showDelelt} onHide={handleCloseDelete}>
              <Modal.Header closeButton>
                <Modal.Title> Detele</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure
                {/* <div className='mb-10 d-flex'>
                  <div>
                    <h6>Feature Image</h6>
                    <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div>
                    <div>
                      <h6>Product Name</h6>
                      <p>{roleID?.title}</p>
                      <h6>Teg</h6>
                      <p>{roleID?.tegs}</p>
                    </div>
                    <h6>product Image</h6>
                    <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={handleCloseDelete}>
                  No
                </Button>
                <Button variant='primary' onClick={hendleDelete}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            {/* <!--begin::Page title-->  */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              {/* <!--begin::Title-->  */}
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                Project
              </h1>
              {/* <!--end::Title-->  */}
              {/* <!--begin::Breadcrumb-->  */}
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>
                  {/* <a href='../../demo1/dist/index.html' className='text-muted text-hover-primary'>
                    Home
                  </a> */}
                  <Link to={'/'} className='text-muted text-hover-primary'>
                    Home
                  </Link>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>
                {/* <!--end::Item-->  */}
                {/* <!--begin::Item-->  */}
                <li className='breadcrumb-item text-muted'>Project</li>
                {/* <!--end::Item-->  */}
              </ul>
              {/* <!--end::Breadcrumb-->  */}
            </div>
            {/* <!--end::Page title-->  */}
            {/* <!--begin::Actions-->  */}
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              {/* <!--begin::Filter menu-->  */}
              <div className='m-0'>
                {/* <!--begin::Menu toggle-->  */}

                {/* <!--end::Menu toggle-->  */}
                {/* <!--begin::Menu 1-->  */}
                <div
                  className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                  data-kt-menu='true'
                  id='kt_menu_637dc7729cedd'
                >
                  {/* <!--begin::Header-->  */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                  </div>
                  {/* <!--end::Header-->  */}
                  {/* <!--begin::Menu separator-->  */}
                  <div className='separator border-gray-200'></div>
                  {/* <!--end::Menu separator-->  */}
                  {/* <!--begin::Form-->  */}
                  <div className='px-7 py-5'>
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Status:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Input-->  */}
                      <div>
                        <select
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-dropdown-parent='#kt_menu_637dc7729cedd'
                          data-allow-clear='true'
                        >
                          <option></option>
                          <option value='1'>Approved</option>
                          <option value='2'>Pending</option>
                          <option value='2'>In Process</option>
                          <option value='2'>Rejected</option>
                        </select>
                      </div>
                      {/* <!--end::Input-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Member Type:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Options-->  */}
                      <div className='d-flex'>
                        {/* <!--begin::Options-->  */}
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                          <input className='form-check-input' type='checkbox' value='1' />
                          <span className='form-check-label'>Author</span>
                        </label>
                        {/* <!--end::Options-->  */}
                        {/* <!--begin::Options-->  */}
                        <label className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value='2'
                            //   checked='checked'
                          />
                          <span className='form-check-label'>Customer</span>
                        </label>
                        {/* <!--end::Options-->  */}
                      </div>
                      {/* <!--end::Options-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Input group-->  */}
                    <div className='mb-10'>
                      {/* <!--begin::Label-->  */}
                      <label className='form-label fw-semibold'>Notifications:</label>
                      {/* <!--end::Label-->  */}
                      {/* <!--begin::Switch-->  */}
                      <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          name='notifications'
                          // checked='checked'
                        />
                        <label className='form-check-label'>Enabled</label>
                      </div>
                      {/* <!--end::Switch-->  */}
                    </div>
                    {/* <!--end::Input group-->  */}
                    {/* <!--begin::Actions-->  */}
                    <div className='d-flex justify-content-end'>
                      <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                      >
                        Reset
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                      >
                        Apply
                      </button>
                    </div>
                    {/* <!--end::Actions-->  */}
                  </div>
                  {/* <!--end::Form-->  */}
                </div>
                {/* <!--end::Menu 1-->  */}
              </div>
              {/* <!--end::Filter menu-->  */}
              {/* <!--begin::Secondary button-->  */}
              {/* <!--end::Secondary button-->  */}
              {/* <!--begin::Primary button-->  */}

              {/* <!--end::Primary button-->  */}
            </div>
            {/* <!--end::Actions-->  */}
          </div>
          {/* <!--end::Toolbar container-->  */}
        </div>

        {boxShow == '0' && (
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            {/* <!--begin::Content container-->  */}
            <div id='kt_app_content_container' className='app-container container-xxl'>
              {/* <!--begin::Card-->  */}
              <div className='card card-flush'>
                {/* <!--begin::Card header-->  */}
                <div className='card-header mt-6'>
                  {/* <!--begin::Card title-->  */}
                  <div className='card-title'>
                    {/* <!--begin::Search-->  */}
                    <div className='d-flex align-items-center position-relative my-1 me-5'>
                      {/* <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->  */}
                      <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.5'
                            x='17.0365'
                            y='15.1223'
                            width='8.15546'
                            height='2'
                            rx='1'
                            transform='rotate(45 17.0365 15.1223)'
                            fill='currentColor'
                          />
                          <path
                            d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}
                      {/* <input
                        type='text'
                        data-kt-permissions-table-filter='search'
                        className='form-control form-control-solid w-250px ps-15'
                        placeholder='Search project'
                      /> */}
                    </div>
                    {/* <!--end::Search-->  */}
                  </div>
                  {/* <!--end::Card title-->  */}
                  {/* <!--begin::Card toolbar-->  */}
                  <div className='card-toolbar'>
                    {/* <!--begin::Button-->  */}
                    <button
                      type='button'
                      className='btn btn-light-primary'
                      // data-bs-toggle='modal'
                      // data-bs-target='#kt_modal_add_permission'
                      onClick={handleShow}
                    >
                      {/* <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->  */}
                      <span className='svg-icon svg-icon-3'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x='2'
                            y='2'
                            width='20'
                            height='20'
                            rx='5'
                            fill='currentColor'
                          />
                          <rect
                            x='10.8891'
                            y='17.8033'
                            width='12'
                            height='2'
                            rx='1'
                            transform='rotate(-90 10.8891 17.8033)'
                            fill='currentColor'
                          />
                          <rect
                            x='6.01041'
                            y='10.9247'
                            width='12'
                            height='2'
                            rx='1'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      {/* <!--end::Svg Icon-->  */}Add Project
                    </button>
                    {/* <!--end::Button-->  */}
                    {/* <Button variant='primary' onClick={handleShow}>
                  Launch demo modal
                </Button> */}
                  </div>
                  {/* <!--end::Card toolbar-->  */}
                </div>
                {/* <!--end::Card header-->  */}
                {/* <!--begin::Card body-->  */}
                <div className='card-body pt-0'>
                  {/* <!--begin::Table-->  */}
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5 mb-0'
                    id='kt_permissions_table'
                  >
                    {/* <!--begin::Table head-->  */}
                    <thead>
                      {/* <!--begin::Table row-->  */}
                      {/* <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-125px'>Name</th>
                    <th className='min-w-250px'>Assigned to</th>
                    <th className='min-w-125px'>Created Date</th>
                    <th className='text-end min-w-100px'>Actions</th>
                  </tr> */}

                      <tr>
                        {headers.map((data: any, i: any) => (
                          <th
                            key={data?.name}
                            onClick={() => (data?.sortable ? onSortingChange(data?.field) : null)}
                          >
                            {data?.name}

                            {sortingField &&
                              sortingField === data?.field &&
                              (sortingOrder === 'asc' ? (
                                //   <FontAwesomeIcon icon='fa-solid fa-arrow-down' />
                                <i className='fa-solid fa-arrow-down'></i>
                              ) : (
                                <i className='fas fa-arrow-alt-up'></i>
                              ))}
                          </th>
                        ))}
                      </tr>

                      {/* <TableHeader
                    headers={headers}
                    onSorting={(field: any, order: any) => setSorting({field, order})}
                  /> */}
                      {/* <!--end::Table row-->  */}
                    </thead>
                    {/* <!--end::Table head-->  */}
                    {/* <!--begin::Table body-->  */}
                    <tbody className='fw-semibold text-gray-600'>
                      {commentsData?.map((data: any, i: any) => {
                        const So = 1 + i
                        const pgt1 = pageNo - 1
                        const pgt = pgt1 * 10
                        const soNo = pgt + So

                        // console.log(data)

                        return (
                          <tr>
                            {/* <!--begin::Name=-->  */}
                            {/* <td>User Management</td> */}
                            {/* <!--end::Name=-->  */}
                            {/* <!--begin::Assigned to=-->  */}
                            <td>
                              {soNo}
                              {/* <a
                            href='../../demo1/dist/apps/user-management/roles/view.html'
                            className='badge badge-light-primary fs-7 m-1'
                          >
                            Administrator
                          </a> */}
                            </td>
                            {/* <!--end::Assigned to=-->  */}
                            {/* <!--begin::Created Date-->  */}
                            <td style={{width: '50px'}}>
                              <img
                                style={{width: '100%'}}
                                src={URL.API_BASE_URL + data?.featureimage}
                                alt=''
                              />{' '}
                            </td>
                            <td>{data?.title}</td>
                            <td>{data?.platform}</td>
                            {/* <td>
                           
                            <button
                              data-id='2209'
                              onClick={() => {
                                const statusValue =
                                  data?.is_status == 'Active' ? 'Inactive' : 'Active'
                                hendleStatusUpdate(data?._id, statusValue)
                              }}
                              className={
                                data?.is_status == 'Active'
                                  ? 'btn btn-sm btn-success viewItem'
                                  : 'btn btn-sm btn-danger viewItem'
                              }
                            >
                              {data?.is_status}
                            </button>
                          </td> */}
                            {/* <td>{data?.delete ? 'Yes' : 'No'}</td> */}
                            {/* <!--end::Created Date-->  */}
                            {/* <!--begin::Action=-->  */}
                            <td>
                              {/* <!--begin::Update-->  */}

                              {/* <td className="text-end"> */}

                              {/* <Dropdown>
                              <Dropdown.Toggle
                                variant='btn btn-light btn-active-light-primary btn-sm'
                                id='dropdown-basic'
                              >
                                Action
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                          
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleToaddPer(data)
                                  }}
                                >
                                  View
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    hendleModalShow(data?._id)
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}

                              {data?.details !== null && (
                                <button
                                  data-id='2209'
                                  onClick={() => {
                                    hendleToaddPer(data?.id)
                                  }}
                                  className='btn btn-sm btn-info viewItem'
                                >
                                  <i className='fas fa-eye'></i>
                                </button>
                              )}
                              <button
                                data-id='2209'
                                onClick={() => {
                                  hendleEditPage(data)
                                }}
                                className='btn btn-sm btn-info ms-3'
                              >
                                <i className='fas fa-edit'></i>
                              </button>
                              <button
                                data-id='2209'
                                onClick={() => {
                                  hendleModalShow(data?.id)
                                }}
                                className='btn btn-sm btn-danger ms-3'
                              >
                                <i className='fas fa-trash-alt'></i>
                              </button>

                              {data?.details == null && (
                                <button
                                  data-id='2209'
                                  onClick={() => {
                                    hendleToDetailModal(data?.id)
                                  }}
                                  className='btn btn-sm btn-info viewItem ms-3'
                                >
                                  <i className='fas fa-plus'></i>
                                </button>
                              )}
                              {/* <!--end::Delete-->  */}
                            </td>
                            {/* <!--end::Action=-->  */}
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* <!--end::Table body-->  */}
                  </table>
                  {/* <div className='col-md-6'> */}
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='next'
                    className='dataTables_paginate paging_simple_numbers category'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel='previous'
                    forcePage={page ? page - 1 : 0}
                    // renderOnZeroPageCount={null}
                  />
                  {/* </div> */}
                  {/* <!--end::Table-->  */}
                </div>

                {/* <!--end::Card body-->  */}
              </div>
              {/* <!--end::Card-->  */}
              {/* <!--begin::Modals-->  */}
              {/* <!--begin::Modal - Add permissions-->  */}
              <div
                className='modal fade'
                id='kt_modal_add_permission'
                tabIndex={-1}
                aria-hidden='true'
              >
                {/* <!--begin::Modal dialog-->  */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/* <!--begin::Modal content-->  */}
                  <div className='modal-content'>
                    {/* <!--begin::Modal header-->  */}
                    <div className='modal-header'>
                      {/* <!--begin::Modal title-->  */}
                      <h2 className='fw-bold'>Add a Permission</h2>
                      {/* <!--end::Modal title-->  */}
                      {/* <!--begin::Close-->  */}
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-permissions-modal-action='close'
                      >
                        {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              opacity='0.5'
                              x='6'
                              y='17.3137'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(-45 6 17.3137)'
                              fill='currentColor'
                            />
                            <rect
                              x='7.41422'
                              y='6'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(45 7.41422 6)'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        {/* <!--end::Svg Icon-->  */}
                      </div>
                      {/* <!--end::Close-->  */}
                    </div>
                    {/* <!--end::Modal header-->  */}
                    {/* <!--begin::Modal body-->  */}
                    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                      {/* <!--begin::Form-->  */}
                      <form id='kt_modal_add_permission_form' className='form' action='#'>
                        {/* <!--begin::Input group-->  */}
                        <div className='fv-row mb-7'>
                          {/* <!--begin::Label-->  */}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Permission Name</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='popover'
                              data-bs-trigger='hover'
                              data-bs-html='true'
                              data-bs-content='Permission names is required to be unique.'
                            ></i>
                          </label>
                          {/* <!--end::Label-->  */}
                          {/* <!--begin::Input-->  */}
                          <input
                            className='form-control form-control-solid'
                            placeholder='Enter a permission name'
                            name='permission_name'
                          />
                          {/* <!--end::Input-->  */}
                        </div>
                        {/* <!--end::Input group-->  */}
                        {/* <!--begin::Input group-->  */}
                        <div className='fv-row mb-7'>
                          {/* <!--begin::Checkbox-->  */}
                          <label className='form-check form-check-custom form-check-solid me-9'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              name='permissions_core'
                              id='kt_permissions_core'
                            />
                            <span className='form-check-label' /* for='kt_permissions_core' */>
                              Set as core permission
                            </span>
                          </label>
                          {/* <!--end::Checkbox-->  */}
                        </div>
                        {/* <!--end::Input group-->  */}
                        {/* <!--begin::Disclaimer-->  */}
                        <div className='text-gray-600'>
                          Permission set as a<strong className='me-1'>Core Permission</strong>will
                          be locked and
                          <strong className='me-1'>not editable</strong>in future
                        </div>
                        {/* <!--end::Disclaimer-->  */}
                        {/* <!--begin::Actions-->  */}
                        <div className='text-center pt-15'>
                          <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-kt-permissions-modal-action='cancel'
                          >
                            Discard
                          </button>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-permissions-modal-action='submit'
                          >
                            <span className='indicator-label'>Submit</span>
                            <span className='indicator-progress'>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                        </div>
                        {/* <!--end::Actions-->  */}
                      </form>
                      {/* <!--end::Form-->  */}
                    </div>
                    {/* <!--end::Modal body-->  */}
                  </div>
                  {/* <!--end::Modal content-->  */}
                </div>
                {/* <!--end::Modal dialog-->  */}
              </div>
              {/* <!--end::Modal - Add permissions-->  */}
              {/* <!--begin::Modal - Update permissions-->  */}
              <div
                className='modal fade'
                id='kt_modal_update_permission'
                tabIndex={-1}
                aria-hidden='true'
              >
                {/* <!--begin::Modal dialog-->  */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                  {/* <!--begin::Modal content-->  */}
                  <div className='modal-content'>
                    {/* <!--begin::Modal header-->  */}
                    <div className='modal-header'>
                      {/* <!--begin::Modal title-->  */}
                      <h2 className='fw-bold'>Update Permission</h2>
                      {/* <!--end::Modal title-->  */}
                      {/* <!--begin::Close-->  */}
                      <div
                        className='btn btn-icon btn-sm btn-active-icon-primary'
                        data-kt-permissions-modal-action='close'
                      >
                        {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->  */}
                        <span className='svg-icon svg-icon-1'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              opacity='0.5'
                              x='6'
                              y='17.3137'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(-45 6 17.3137)'
                              fill='currentColor'
                            />
                            <rect
                              x='7.41422'
                              y='6'
                              width='16'
                              height='2'
                              rx='1'
                              transform='rotate(45 7.41422 6)'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        {/* <!--end::Svg Icon-->  */}
                      </div>
                      {/* <!--end::Close-->  */}
                    </div>
                    {/* <!--end::Modal header-->  */}
                    {/* <!--begin::Modal body-->  */}
                    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                      {/* <!--begin::Notice-->  */}
                      {/* <!--begin::Notice-->  */}
                      <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                        {/* <!--begin::Icon-->  */}
                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->  */}
                        <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
                          <svg
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <rect
                              opacity='0.3'
                              x='2'
                              y='2'
                              width='20'
                              height='20'
                              rx='10'
                              fill='currentColor'
                            />
                            <rect
                              x='11'
                              y='14'
                              width='7'
                              height='2'
                              rx='1'
                              transform='rotate(-90 11 14)'
                              fill='currentColor'
                            />
                            <rect
                              x='11'
                              y='17'
                              width='2'
                              height='2'
                              rx='1'
                              transform='rotate(-90 11 17)'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                        {/* <!--end::Svg Icon-->  */}
                        {/* <!--end::Icon-->  */}
                        {/* <!--begin::Wrapper-->  */}
                        <div className='d-flex flex-stack flex-grow-1'>
                          {/* <!--begin::Content-->  */}
                          <div className='fw-semibold'>
                            <div className='fs-6 text-gray-700'>
                              <strong className='me-1'>Warning!</strong>By editing the permission
                              name, you might break the system permissions functionality. Please
                              ensure you're absolutely certain before proceeding.
                            </div>
                          </div>
                          {/* <!--end::Content-->  */}
                        </div>
                        {/* <!--end::Wrapper-->  */}
                      </div>
                      {/* <!--end::Notice-->  */}
                      {/* <!--end::Notice-->  */}
                      {/* <!--begin::Form-->  */}
                      <form id='kt_modal_update_permission_form' className='form' action='#'>
                        {/* <!--begin::Input group-->  */}
                        <div className='fv-row mb-7'>
                          {/* <!--begin::Label-->  */}
                          <label className='fs-6 fw-semibold form-label mb-2'>
                            <span className='required'>Permission Name</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='popover'
                              data-bs-trigger='hover'
                              data-bs-html='true'
                              data-bs-content='Permission names is required to be unique.'
                            ></i>
                          </label>
                          {/* <!--end::Label-->  */}
                          {/* <!--begin::Input-->  */}
                          <input
                            className='form-control form-control-solid'
                            placeholder='Enter a permission name'
                            name='permission_name'
                          />
                          {/* <!--end::Input-->  */}
                        </div>
                        {/* <!--end::Input group-->  */}
                        {/* <!--begin::Actions-->  */}
                        <div className='text-center pt-15'>
                          <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-kt-permissions-modal-action='cancel'
                          >
                            Discard
                          </button>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-permissions-modal-action='submit'
                          >
                            <span className='indicator-label'>Submit</span>
                            <span className='indicator-progress'>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          </button>
                        </div>
                        {/* <!--end::Actions-->  */}
                      </form>
                      {/* <!--end::Form-->  */}
                    </div>
                    {/* <!--end::Modal body-->  */}
                  </div>
                  {/* <!--end::Modal content-->  */}
                </div>
                {/* <!--end::Modal dialog-->  */}
              </div>
              {/* <!--end::Modal - Update permissions-->  */}
              {/* <!--end::Modals-->  */}
            </div>
            {/* <!--end::Content container-->  */}
          </div>
        )}

        {boxShow == '1' && (
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            {/* <!--begin::Content container-->  */}
            <div id='kt_app_content_container' className='app-container container-xxl'>
              {/* <!--begin::Card-->  */}
              <div className='card card-flush p-6'>
                <Modal.Body>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>clientName</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a clientName'
                      name='clientName'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>slug</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a slug'
                      name='slug'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>longDescription</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a longDescription'
                      name='longDescription'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>playstoreLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a playstoreLink'
                      name='playstoreLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>appstoreLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a appstoreLink'
                      name='appstoreLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  {/* <div className='form-group col-md-6'>
                  <label>Start Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => setStartDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label>deliver date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: any) => setEndDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control bg-transparent'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div> */}
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Start Dat</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date: any) => setStartDate(date)}
                      // showTimeSelect
                      //   timeFormat="HH:mm:ss"
                      className='form-control'
                      //   minDate={new Date()}
                      placeholderText='Date & Time'
                      dateFormat='yyyy-MM-dd'
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>deliver date</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date: any) => setEndDate(date)}
                      // showTimeSelect
                      //   timeFormat="HH:mm:ss"
                      className='form-control bg-transparent'
                      //   minDate={new Date()}
                      placeholderText='Date & Time'
                      dateFormat='yyyy-MM-dd'
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>webLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a webLink'
                      name='webLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>adminLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a adminLink'
                      name='adminLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>demousername</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a demousername'
                      name='demousername'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>demopassword</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a demopassword'
                      name='demopassword'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>demopin</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a demopin'
                      name='demopin'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>versioncode</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a versioncode'
                      name='versioncode'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>versionname</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a versionname'
                      name='versionname'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Description</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <CKEditor
                      editor={Editor}
                      data={aboutPage}
                      onReady={(editor: any) => {
                        // console.log('SDfsf')
                        // console.log(editor)
                      }}
                      onChange={(event: any, editor: any) => {
                        // console.log(editor)
                        const data = editor.getData()
                        setaboutPage(data)
                      }}
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      // config={{
                      //   // plugins: [ Essentials ],
                      //   ckfinder: {
                      //     // The URL that the images are uploaded to.
                      //     uploadUrl: `${URL.API_BASE_URL}/apiPublic/productcon`,

                      //     // Enable the XMLHttpRequest.withCredentials property.
                      //     withCredentials: true,

                      //     // Headers sent along with the XMLHttpRequest to the upload server.
                      //     headers: {
                      //       "X-CSRF-TOKEN": "CSFR-Token",
                      //       Authorization: "Bearer <JSON Web Token>",
                      //     },
                      //   },
                      // }}
                      onBlur={(event: any, editor: any) => {
                        console.log('SDfsf')
                      }}
                      onFocus={(event: any, editor: any) => {
                        console.log('SDfsf')
                      }}
                    />
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Image</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <ImageUploading
                      multiple
                      value={imagesss}
                      onChange={onChangess}
                      maxNumber={maxNumberss}
                      dataURLKey='data_url'
                      acceptType={['png',"jpg", "jpeg", "svg"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className='upload__image-wrapper'>
                          <button
                            className='btn btn-secondary'
                            style={isDragging ? {color: 'red'} : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Choose image
                          </button>
                          &nbsp;
                          <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className='image-item'>
                              <img src={image.data_url} alt='' width='100' />
                              <div className='image-item__btn-wrapper'>
                                <button
                                  className='btn btn-secondary'
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className='btn btn-secondary'
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      className='btn btn-light me-3'
                      data-kt-permissions-modal-action='cancel'
                      onClick={() => {
                        setboxShow('0')
                      }}
                    >
                      Discard
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      onClick={() => {
                        hendleToAddDetails()
                      }}
                      data-kt-permissions-modal-action='submit'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* </form> */}
                </Modal.Body>
              </div>
            </div>
            {/* <!--end::Content container-->  */}
          </div>
        )}

        {boxShow == '2' && (
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            {/* <!--begin::Content container-->  */}
            <div id='kt_app_content_container' className='app-container container-xxl'>
              {/* <!--begin::Card-->  */}
              <div className='card card-flush p-6'>
                <Modal.Body>
                  {/* Woohoo, you're reading this text in a modal! */}

                  <div className='mb-10 d-flex'>
                    <div className='col-12'>
                      {/* get_seller_profile */}
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>Project Name</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.title}</p>
                        </div>
                      </div>

                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>clientName</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.clientName}</p>
                        </div>
                      </div>

                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>platform</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.platform}</p>
                        </div>
                      </div>

                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>demousername</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.demousername}</p>
                        </div>
                      </div>

                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>islive</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.islive}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>slug</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.slug}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>versionname</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.versionname}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>webLink</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.webLink}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>demopin</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.demopin}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>demopassword</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.demopassword}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>startAt</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.startAt}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>deliverAt</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.deliverAt}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>appstoreLink</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.appstoreLink}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>adminLink</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.adminLink}</p>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <div className='col-3'>
                          <h6>versioncode</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>{get_project_details?.projects?.versioncode}</p>
                        </div>
                      </div>

                      {/* <div className='d-flex'>
                        <div className='col-3'>
                          <h6>Password</h6>
                        </div>
                        <div className='col-9'>
                          {' '}
                          <p>dfgdfg</p>
                        </div>
                      </div> */}

                      {/* <h6>Email</h6>
                      <p>{get_seller_profile?.email}</p>
                      <h6>Role</h6>
                      <p>{get_seller_profile?.role_name}</p> */}
                      {/* <h3>Buseniss</h3>

                      <div className='d-flex mb-5'>
                        <div className='col-4'>
                         
                        </div>
                        <div className='col-8'>
                          <div className='d-flex'>
                            <div className='col-3'>
                              <h6>Bussniss Name</h6>
                            </div>
                            <div className='col-9'>
                              {' '}
                              <p>sdfsdf</p>
                            </div>
                          </div>
                          <div className='d-flex'>
                            <div className='col-3'>
                              <h6>City</h6>
                            </div>
                            <div className='col-9'>
                              {' '}
                              <p>dsfsdf</p>
                            </div>
                          </div>
                          <div className='d-flex'>
                            <div className='col-3'>
                              <h6>Country</h6>
                            </div>
                            <div className='col-9'>
                              {' '}
                              <p>dfsdf</p>
                            </div>
                          </div>
                          <div className='d-flex'>
                            <div className='col-3'>
                              <h6>Address</h6>
                            </div>
                            <div className='col-9'>
                              {' '}
                              <p>sdfsdf</p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className='text-center pt-15'>
                        <button
                          type='reset'
                          className='btn btn-light me-3'
                          data-kt-permissions-modal-action='cancel'
                          onClick={() => {
                            setboxShow('0')
                          }}
                        >
                          Discard
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          onClick={() => {
                            hendleToAddDetails()
                          }}
                          data-kt-permissions-modal-action='submit'
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && <span className='indicator-label'>Submit</span>}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </div>
            </div>
            {/* <!--end::Content container-->  */}
          </div>
        )}

        {boxShow == '3' && (
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            {/* <!--begin::Content container-->  */}
            <div id='kt_app_content_container' className='app-container container-xxl'>
              {/* <!--begin::Card-->  */}
              <div className='card card-flush p-6'>
                <Modal.Body>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>clientName</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a clientName'
                      name='clientName'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>slug</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a slug'
                      name='slug'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>longDescription</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a longDescription'
                      name='longDescription'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>playstoreLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a playstoreLink'
                      name='playstoreLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>appstoreLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a appstoreLink'
                      name='appstoreLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  {/* <div className='form-group col-md-6'>
                  <label>Start Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => setStartDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label>deliver date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date: any) => setEndDate(date)}
                    // showTimeSelect
                    //   timeFormat="HH:mm:ss"
                    className='form-control bg-transparent'
                    //   minDate={new Date()}
                    placeholderText='Date & Time'
                    dateFormat='yyyy-MM-dd'
                  />
                </div> */}
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Start Dat</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date: any) => setStartDate(date)}
                      // showTimeSelect
                      //   timeFormat="HH:mm:ss"
                      className='form-control'
                      //   minDate={new Date()}
                      placeholderText='Date & Time'
                      dateFormat='yyyy-MM-dd'
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>deliver date</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date: any) => setEndDate(date)}
                      // showTimeSelect
                      //   timeFormat="HH:mm:ss"
                      className='form-control bg-transparent'
                      //   minDate={new Date()}
                      placeholderText='Date & Time'
                      dateFormat='yyyy-MM-dd'
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>webLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a webLink'
                      name='webLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>adminLink</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a adminLink'
                      name='adminLink'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>demousername</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a demousername'
                      name='demousername'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>demopassword</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a demopassword'
                      name='demopassword'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>demopin</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a demopin'
                      name='demopin'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>versioncode</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a versioncode'
                      name='versioncode'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>versionname</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <input
                      // className='form-control form-control-solid'
                      placeholder='Enter a versionname'
                      name='versionname'
                      onChange={(e) => {
                        hendleToAdd(e)
                      }}
                      // {...formik.getFieldProps('role_name')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.role_name && formik.errors.role_name},
                        {
                          'is-valid': formik.touched.role_name && !formik.errors.role_name,
                        }
                      )}
                      // onChange={(e:any)=>{hendleToAdd(e)}}
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.role_name}</span>
                      </div>
                    )}
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Description</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <CKEditor
                      editor={Editor}
                      data={aboutPage}
                      onReady={(editor: any) => {
                        // console.log('SDfsf')
                        // console.log(editor)
                      }}
                      onChange={(event: any, editor: any) => {
                        // console.log(editor)
                        const data = editor.getData()
                        setaboutPage(data)
                      }}
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      // config={{
                      //   // plugins: [ Essentials ],
                      //   ckfinder: {
                      //     // The URL that the images are uploaded to.
                      //     uploadUrl: `${URL.API_BASE_URL}/apiPublic/productcon`,

                      //     // Enable the XMLHttpRequest.withCredentials property.
                      //     withCredentials: true,

                      //     // Headers sent along with the XMLHttpRequest to the upload server.
                      //     headers: {
                      //       "X-CSRF-TOKEN": "CSFR-Token",
                      //       Authorization: "Bearer <JSON Web Token>",
                      //     },
                      //   },
                      // }}
                      onBlur={(event: any, editor: any) => {
                        console.log('SDfsf')
                      }}
                      onFocus={(event: any, editor: any) => {
                        console.log('SDfsf')
                      }}
                    />
                  </div>

                  <div className='fv-row mb-7'>
                    <label className='fs-6 fw-semibold form-label mb-2'>
                      <span className='required'>Image</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='popover'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-content='Permission names is required to be unique.'
                      ></i>
                    </label>
                    <ImageUploading
                      multiple
                      value={imagesss}
                      onChange={onChangess}
                      maxNumber={maxNumberss}
                      dataURLKey='data_url'
                      acceptType={['png',"jpg", "jpeg", "svg"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className='upload__image-wrapper'>
                          <button
                            className='btn btn-secondary'
                            style={isDragging ? {color: 'red'} : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Choose image
                          </button>
                          &nbsp;
                          <button className='btn btn-secondary' onClick={onImageRemoveAll}>
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className='image-item'>
                              <img src={image.data_url} alt='' width='100' />
                              <div className='image-item__btn-wrapper'>
                                <button
                                  className='btn btn-secondary'
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className='btn btn-secondary'
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <div className='text-center pt-15'>
                    <button
                      type='reset'
                      className='btn btn-light me-3'
                      data-kt-permissions-modal-action='cancel'
                      onClick={() => {
                        setboxShow('0')
                      }}
                    >
                      Discard
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      onClick={() => {
                        hendleToAddDetails()
                      }}
                      data-kt-permissions-modal-action='submit'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/* </form> */}
                </Modal.Body>
              </div>
            </div>
            {/* <!--end::Content container-->  */}
          </div>
        )}

        {/* <!--end::Content-->  */}
      </div>
      {/* <!--end::Content wrapper-->  */}
      {/* <!--begin::Footer-->  */}

      {/* <!--end::Footer-->  */}
      {/* </div> */}
    </div>
  )
}

export default Project
