import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {adminAction} from '../../../redux/common/action'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'

import ImageUploading from 'react-images-uploading'
import {toast} from 'react-toastify'
// @ts-ignore
import {Country, State, City} from 'country-state-city'

import Select from 'react-select'
import {URL} from '../../../redux/common/url'
import Footer from './Footer'
import PageList from './PageList'

function Setting() {
  const location = useLocation()
  const dispatch = useDispatch()

  const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const [showOtpBoxsata, setShowOtpBoxdata] = useState<any>('')

  const [images, setImages] = React.useState<any>([])
  const maxNumber = 1

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList)
  }

  const [imagesss, setImagesss] = React.useState<any>([])
  const [imagesss3, setImagesss3] = React.useState<any>([])
  const maxNumberss = 1

  const onChangess = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImagesss(imageList)
  }
  const onChangess3 = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImagesss3(imageList)
  }

  // getAdminBussProfile
  //   get_admin_profile

  const get_admin_profile: any = useSelector((state: any) =>
    state.admin.get_invoice_details ? state.admin.get_invoice_details : {}
  )
  const get_setting: any = useSelector((state: any) =>
    state.admin.get_setting ? state.admin.get_setting : {}
  )

  var size = Object.keys(get_admin_profile).length

  console.log(get_setting)

  useEffect(() => {
    // dispatch(adminAction.getInvoceDetails({}))
    dispatch(adminAction.getSettings({}))

    return () => {}
  }, [])

  //   createBussnissPro
  //   createBussnissPro

  const [show, setShow] = useState<any>(false)
  //   const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [userBuss, setuserBuss] = useState<any>({
    gstNo: '',
    companyName: '',
    email: '',
    address: '',
    fb: '',
    insta: '',
    twitter: '',
    youtube: '',
    number: '',
  })

  const hendleChangerole = (e: any) => {
    const {name, value} = e.target
    setuserBuss({...userBuss, [name]: value})
  }

  const countryList = Country.getAllCountries()
  const cityListList = City.getAllCities()

  const [subList, setsubList] = useState<any>([])
  const [subListCity, setsubListCity] = useState<any>([])

  useEffect(() => {
    const dropList: any = countryList?.map((data: any, i: any) => {
      data.value = data?.isoCode
      data.label = data?.name

      return data
    })

    setsubList(dropList)
  }, [countryList])

  useEffect(() => {
    const dropList: any = cityListList?.map((data: any, i: any) => {
      data.value = data?.stateCode
      data.label = data?.name

      return data
    })

    setsubListCity(dropList)
  }, [cityListList])

  const [subListCityfil, setsubListCityfil] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [selectedOptionCity, setSelectedOptionCity] = useState<any>(null)

  useEffect(() => {
    // const found = subListCity.find(
    //   (element) => element?.countryCode == selectedOption?.isoCode
    // );

    const listFilCity: any =
      subListCity &&
      subListCity?.filter((data: any) => {
        return data?.countryCode == selectedOption?.isoCode
      })
    // console.log(listFilCity);
    setsubListCityfil(listFilCity)
    setSelectedOptionCity(null)
  }, [selectedOption])

  const submitProduct = () => {
    try {
      const data = new FormData()

      if (!userBuss?.gstNo) {
        toast.error('please enter gst no.')
      } else if (!userBuss?.companyName) {
        toast.error('please enter company name')
      } else if (!userBuss?.email) {
        toast.error('please enter email')
      } else if (!userBuss?.address) {
        toast.error('please enter address')
      } else if (images?.length == 0) {
        toast.error('please select logo Image')
      } else {
        // setLoading(true);
        // gstNo
        // companyName
        // email
        // address

        data.append('gstNo', userBuss?.gstNo)
        data.append('companyName', userBuss?.companyName)
        data.append('email', userBuss?.email)
        data.append('address', userBuss?.address)
        data.append('fb', userBuss?.fb)
        data.append('insta', userBuss?.insta)
        data.append('twitter', userBuss?.twitter)
        data.append('youtube', userBuss?.youtube)
        data.append('number', userBuss?.number)

        data.append('invoiceLogo', images[0]?.file)

        dispatch(adminAction.createAllsettings(data))
        // setShow(false);
        // setShowOtpBox(true)
        setShowOtpBox(false)
        setShow(false)
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const submitChangeVerify2 = () => {
    if (!showOtpBoxsata) {
      toast.error('please enter otp')
    } else if (showOtpBoxsata?.length < 6 || showOtpBoxsata?.length > 6) {
      toast.error('otp invalid')
    } else {
      dispatch(
        adminAction.verifyWhatsapp({
          whatsappNumber: userBuss?.whatsappNumber,
          otp: showOtpBoxsata,
        })
      )
      setShowOtpBox(false)
      setShow(false)
    }
  }

  const [showwhat, setShowwhat] = useState<any>(false)

  const handleClosewhat = () => setShowwhat(false)
  const handleShowwhat = () => setShowwhat(true)

  const [showLogo, setshowLogo] = useState<any>(false)

  const handleCloseshowLogo = () => setshowLogo(false)
  const handleShowshowLogo = () => setshowLogo(true)

  const [showeditBox, setshoweditBox] = useState<any>(false)

  const handleCloseeditBox = () => setshoweditBox(false)
  const handleShoweditBox = () => setshoweditBox(true)

  const [showOtp, setshowOtp] = useState<any>(false)
  const [whatsappNum, setwhatsappNum] = useState<any>('')
  const [countryCode, setcountryCode] = useState<any>('')
  const [whatsappOtp, setwhatsappOtp] = useState<any>('')
  const [bussEdit, setbussEdit] = useState<any>({
    gstNo: '',
    companyName: '',
    email: '',
    address: '',
    fb: '',
    insta: '',
    twitter: '',
    youtube: '',
    number: '',
  })

  const [subListedit, setsubListedit] = useState<any>([])
  const [subListCityedit, setsubListCityedit] = useState<any>([])

  const [selectedOptionEdit, setSelectedOptionEdit] = useState<any>(null)
  const [selectedOptionCityEdit, setSelectedOptionCityEdit] = useState<any>(null)

  useEffect(() => {
    const listFilCity =
      subListCity &&
      subListCity?.filter((data: any) => {
        return data?.countryCode == selectedOptionEdit?.isoCode
      })
    // console.log(listFilCity);
    setsubListCityedit(listFilCity)
    setSelectedOptionCityEdit(null)
  }, [selectedOptionEdit])

  useEffect(() => {
    const dropList = countryList?.map((data: any, i: any) => {
      data.value = data?.isoCode
      data.label = data?.name

      return data
    })

    setsubListedit(dropList)
    let obj = dropList.find((o: any) => o.name === get_admin_profile?.country)

    setSelectedOptionEdit(obj)

    const listFilCity =
      subListCity &&
      subListCity?.filter((data: any) => {
        return data?.countryCode == obj?.isoCode
      })
    console.log(listFilCity)
    console.log(get_admin_profile?.city)
    setsubListCityedit(listFilCity)
    let obj2 = listFilCity.find((o: any) => o.name === get_admin_profile?.city)

    setSelectedOptionCityEdit(obj2)
  }, [get_admin_profile])
  console.log(get_admin_profile)

  useEffect(() => {
    setbussEdit({
      gstNo: get_admin_profile?.gstNo,
      companyName: get_admin_profile?.companyName,
      email: get_admin_profile?.email,
      address: get_admin_profile?.address,
      fb: get_admin_profile?.fb,
      insta: get_admin_profile?.insta,
      twitter: get_admin_profile?.twitter,
      youtube: get_admin_profile?.youtube,
      number: get_admin_profile?.number,
    })

    // setwhatsappNum(get_admin_profile?.user_id?.whatsappNumber)
    // setcountryCode(get_admin_profile?.user_id?.countryCode)
  }, [get_admin_profile])

  const hendleChangeupdate = (e: any) => {
    const {name, value} = e.target
    setbussEdit({...bussEdit, [name]: value})
  }

  const submitupdateBussProfile = () => {
    try {
      const data = new FormData()

      if (!bussEdit?.gstNo) {
        toast.error('please enter GST No.')
      } else if (!bussEdit?.companyName) {
        toast.error('please enter company name')
      } else if (!bussEdit?.email) {
        toast.error('please enter email')
      } else if (!bussEdit?.address) {
        toast.error('please enter address')
      } else {
        // setLoading(true);

        // data.append("businessName", bussEdit?.businessName);
        // data.append("address", bussEdit?.address);
        // data.append("city", bussEdit?.city);
        // data.append("country", bussEdit?.country);
        // data.append("whatsappNumber", bussEdit?.whatsappNumber);

        // data.append("logo", images[0]?.file);

        dispatch(
          adminAction.updateInvoiceProfile({
            gstNo: bussEdit?.gstNo,
            companyName: bussEdit?.companyName,
            email: bussEdit?.email,
            address: bussEdit?.address,
            fb: bussEdit?.fb,
            insta: bussEdit?.insta,
            twitter: bussEdit?.twitter,
            youtube: bussEdit?.youtube,
            number: bussEdit?.number,

            // country: selectedOptionEdit?.name,
            // city: selectedOptionCityEdit?.name,
          })
        )
        setshoweditBox(false)
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const submitChangeW = () => {
    if (!whatsappNum) {
      toast.error('please enter whatsapp number')
    } else if (whatsappNum?.length < 10 || whatsappNum?.length > 10) {
      toast.error('number invalid')
    } else {
      dispatch(
        adminAction.updateWhatsaapNumber({
          whatsappNumber: whatsappNum,
          countryCode: countryCode,
        })
      )
      setshowOtp(true)
    }
  }

  const submitChangeVerify = () => {
    if (!whatsappOtp) {
      toast.error('please enter otp')
    } else if (whatsappOtp?.length < 6 || whatsappOtp?.length > 6) {
      toast.error('otp invalid')
    } else {
      dispatch(
        adminAction.verifyWhatsapp({
          whatsappNumber: whatsappNum,
          otp: whatsappOtp,
        })
      )
      setShowwhat(false)
      setshowOtp(false)
    }
  }

  const hendleLogoUpdate = () => {
    try {
      const data = new FormData()

      if (imagesss?.length == 0) {
        toast.error('please select logo Image')
      } else {
        // setLoading(true);

        data.append('invoiceLogo', imagesss[0]?.file)

        dispatch(adminAction.updateInvoiceLogo(data))
        setshowLogo(false)
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  const [showLogoHome, setshowLogoHome] = useState<any>(false)

  const handleCloseshowLogoHome = () => setshowLogoHome(false)
  const handleShowshowLogoHome = () => setshowLogoHome(true)

  // setImagesss3([{data_url: URL.API_BASE_URL + e.featureImage}])
  // imagesss3, setImagesss3

  const handleShowshowLogoHome3 = (e: any) => {
    console.log(e)
    setImagesss3([{data_url: URL.API_BASE_URL + e}])
    setshowLogoHome(true)
  }

  console.log(imagesss3)

  const hendlehomeLogoUpdate = () => {
    try {
      const data = new FormData()

      if (imagesss3?.length == 0) {
        toast.error('please select logo Image')
      } else {
        // setLoading(true);

        if (imagesss3[0]?.file) {
          // roledata?.role_name
          data.append('homeLogo', imagesss3[0]?.file)
          dispatch(adminAction.updateHomeLogo(data))
        }

        setshowLogoHome(false)
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            {!showOtpBox ? (
              <div className='card-body'>
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className='mb-3'>
                  <label className='form-label'>Gst No</label>
                  <input
                    name='gstNo'
                    className='form-control'
                    placeholder='Enter gst No.'
                    type='text'
                    onChange={(e: any) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                {/* <div className='mb-3'>
                  <label className='form-label'>Select Country</label>
                  <Select
                    className='coustomSelect'
                    classNamePrefix='coustomSelect'
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={subList}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>Select City</label>
                  <Select
                    className='coustomSelect'
                    classNamePrefix='coustomSelect'
                    defaultValue={selectedOptionCity}
                    onChange={setSelectedOptionCity}
                    options={subListCityfil}
                  />
                </div> */}

                <div className='mb-3'>
                  <label className='form-label'>Company Name</label>
                  <input
                    name='companyName'
                    className='form-control'
                    placeholder='Enter company name'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                {/* <div className="mb-3">
                    <label for="teg" className="form-label">
                      City
                    </label>
                    <input
                      name="city"
                      className="form-control"
                      placeholder="Enter city"
                      type="text"
                      onChange={(e) => {
                        hendleChangerole(e);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="teg" className="form-label">
                      Country
                    </label>
                    <input
                      name="country"
                      className="form-control"
                      placeholder="Enter country"
                      type="text"
                      onChange={(e) => {
                        hendleChangerole(e);
                      }}
                    />
                  </div> */}

                <div className='mb-3'>
                  <label className='form-label'>Email</label>
                  <input
                    name='email'
                    className='form-control'
                    placeholder='Enter  email'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>address</label>
                  <input
                    name='address'
                    className='form-control'
                    placeholder='Enter address'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>fb</label>
                  <input
                    name='fb'
                    className='form-control'
                    placeholder='Enter fb'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>insta</label>
                  <input
                    name='insta'
                    className='form-control'
                    placeholder='Enter insta'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>twitter</label>
                  <input
                    name='twitter'
                    className='form-control'
                    placeholder='Enter twitter'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>youtube</label>
                  <input
                    name='youtube'
                    className='form-control'
                    placeholder='Enter youtube'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>number</label>
                  <input
                    name='number'
                    className='form-control'
                    placeholder='Enter number'
                    type='text'
                    onChange={(e) => {
                      hendleChangerole(e)
                    }}
                  />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>Invoice Logo</label>
                  <ImageUploading
                    // multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey='data_url'
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className='upload__image-wrapper'>
                        <button
                          className='btn btn-primary btn-sm rounded'
                          style={isDragging ? {color: 'red'} : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        <button
                          className='btn btn-primary btn-sm rounded'
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className='image-item'>
                            <img src={image['data_url']} alt='' width='100' />
                            <div className='image-item__btn-wrapper'>
                              <button
                                className='btn btn-primary btn-sm rounded'
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className='btn btn-primary btn-sm rounded'
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>

                {/* <div className="mb-3">
                  <label className="form-label">Select Category</label>
                  <Select
                    className="coustomSelect"
                    classNamePrefix="coustomSelect"
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={subList}
                  />
                  
                </div> */}
              </div>
            ) : (
              <div className='card-body'>
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className='mb-3'>
                  <label className='form-label'>Business Name</label>
                  <input
                    name='businessName'
                    className='form-control'
                    placeholder='Enter Business name'
                    type='text'
                    value={showOtpBoxsata}
                    onChange={(e) => {
                      setShowOtpBoxdata(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>

        {!showOtpBox ? (
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitProduct()
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() => {
                submitChangeVerify2()
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={showeditBox} onHide={handleCloseeditBox}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            <div className='card-body'>
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className='mb-3'>
                <label className='form-label'>Gst No</label>
                <input
                  name='gstNo'
                  className='form-control'
                  placeholder='Enter gstNo'
                  type='text'
                  value={bussEdit?.gstNo}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Company Name</label>
                <input
                  name='companyName'
                  className='form-control'
                  placeholder='Enter company name'
                  type='text'
                  value={bussEdit?.companyName}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Email</label>
                <input
                  name='email'
                  className='form-control'
                  placeholder='Enter email'
                  type='text'
                  value={bussEdit?.email}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>

              {/* <div className='mb-3'>
                <label className='form-label'>Select Country</label>
                <Select
                  className='coustomSelect'
                  classNamePrefix='coustomSelect'
                  defaultValue={selectedOptionEdit}
                  onChange={setSelectedOptionEdit}
                  options={subListedit}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Select City</label>
                <Select
                  className='coustomSelect'
                  classNamePrefix='coustomSelect'
                  defaultValue={selectedOptionCityEdit}
                  onChange={setSelectedOptionCityEdit}
                  options={subListCityedit}
                />
              </div> */}
              <div className='mb-3'>
                <label className='form-label'>Address</label>
                <input
                  name='address'
                  className='form-control'
                  placeholder='Enter address'
                  type='text'
                  value={bussEdit?.address}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>

              <div className='mb-3'>
                <label className='form-label'>Fb Link</label>
                <input
                  name='fb'
                  className='form-control'
                  placeholder='Enter fb'
                  type='text'
                  value={bussEdit?.fb}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Insta Link</label>
                <input
                  name='insta'
                  className='form-control'
                  placeholder='Enter insta'
                  type='text'
                  value={bussEdit?.insta}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Twitter Link</label>
                <input
                  name='twitter'
                  className='form-control'
                  placeholder='Enter twitter'
                  type='text'
                  value={bussEdit?.twitter}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Youtube Link</label>
                <input
                  name='youtube'
                  className='form-control'
                  placeholder='Enter youtube'
                  type='text'
                  value={bussEdit?.youtube}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>
              <div className='mb-3'>
                <label className='form-label'>Contact Number</label>
                <input
                  name='number'
                  className='form-control'
                  placeholder='Enter number'
                  type='text'
                  value={bussEdit?.number}
                  onChange={(e) => {
                    hendleChangeupdate(e)
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseeditBox}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              submitupdateBussProfile()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/*  whatsapp */}

      <Modal show={showwhat} onHide={handleClosewhat}>
        <Modal.Header closeButton>
          <Modal.Title>Whatsapp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            {/* <h4 className="card-title mb-4">Feature Image</h4> */}
            {!showOtp ? (
              <div className='card-body'>
                <div className='mb-3'>
                  <label className='form-label'>Whatsapp Number</label>
                  <input
                    name='countryCode'
                    className='form-control'
                    placeholder='Enter whatsapp number'
                    type='text'
                    value={countryCode}
                    onChange={(e) => {
                      setcountryCode(e.target.value)
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Whatsapp Number</label>
                  <input
                    name='whatsappNumber'
                    className='form-control'
                    placeholder='Enter whatsapp number'
                    type='text'
                    value={whatsappNum}
                    onChange={(e) => {
                      setwhatsappNum(e.target.value)
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className='card-body'>
                <div className='mb-3'>
                  <label className='form-label'>Otp</label>
                  <input
                    name='otp'
                    className='form-control'
                    placeholder='Enter whatsapp otp'
                    type='text'
                    value={whatsappOtp}
                    onChange={(e) => {
                      setwhatsappOtp(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {!showOtp ? (
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClosewhat}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitChangeW()
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setshowOtp(false)
              }}
            >
              Change number
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                submitChangeVerify()
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={showLogo} onHide={handleCloseshowLogo}>
        <Modal.Header closeButton>
          <Modal.Title>Change Invoice logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            <div className='card-body'>
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className='mb-3'>
                <label className='form-label'>Invoice Logo</label>
                <ImageUploading
                  // multiple
                  value={imagesss}
                  onChange={onChangess}
                  maxNumber={maxNumberss}
                  dataURLKey='data_url'
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className='upload__image-wrapper'>
                      <button
                        className='btn btn-primary btn-sm rounded'
                        style={isDragging ? {color: 'red'} : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      <button className='btn btn-primary btn-sm rounded' onClick={onImageRemoveAll}>
                        Remove all images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className='image-item'>
                          <img src={image['data_url']} alt='' width='100' />
                          <div className='image-item__btn-wrapper'>
                            <button
                              className='btn btn-primary btn-sm rounded'
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className='btn btn-primary btn-sm rounded mt-2'
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseshowLogo}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              hendleLogoUpdate()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* home logo */}
      <Modal show={showLogoHome} onHide={handleCloseshowLogoHome}>
        <Modal.Header closeButton>
          <Modal.Title>Change Home logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div className='card mx-auto '>
            <div className='card-body'>
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}
              <div className='mb-3'>
                <label className='form-label'> Logo</label>
                <ImageUploading
                  // multiple
                  value={imagesss3}
                  onChange={onChangess3}
                  maxNumber={maxNumberss}
                  dataURLKey='data_url'
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className='upload__image-wrapper'>
                      <button
                        className='btn btn-primary btn-sm rounded'
                        style={isDragging ? {color: 'red'} : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      <button className='btn btn-primary btn-sm rounded' onClick={onImageRemoveAll}>
                        Remove all images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className='image-item'>
                          <img src={image['data_url']} alt='' width='100' />
                          <div className='image-item__btn-wrapper'>
                            <button
                              className='btn btn-primary btn-sm rounded'
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className='btn btn-primary btn-sm rounded mt-2'
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseshowLogoHome}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              hendlehomeLogoUpdate()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <h3>Invoice Details</h3>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img
                  src={toAbsoluteUrl(URL.API_BASE_URL + get_admin_profile?.invoiceLogo)}
                  alt=''
                />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {get_admin_profile?.companyName}
                    </a>
                    <a href='#'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      />
                      {get_admin_profile?.number}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      {/* <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      /> */}
                      {get_admin_profile?.user_id?.whatsappNumber}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {get_admin_profile?.email}
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  {/* <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                  >
                    Hire Me
                  </a> */}

                  {size == 0 ? (
                    <div className='col-xl-4 text-md-end'>
                      <Button variant='primary' onClick={handleShow}>
                        Create Profile
                      </Button>
                      {/* <a href="#" className="btn btn-primary">
                    {" "}
                    View live <i className="material-icons md-launch"></i>{" "}
                  </a> */}
                    </div>
                  ) : (
                    <div className=' text-md-end'>
                      {/* <Button variant='primary ' onClick={handleShowwhat}>
                        Change Whatsapp No.
                      </Button> */}
                      <Button
                        variant='primary ms-2'
                        onClick={() => {
                          handleShowshowLogoHome3(get_admin_profile?.homeLogo)
                        }}
                      >
                        Change Home Logo
                      </Button>
                      <Button variant='primary ms-2' onClick={handleShowshowLogo}>
                        Change Invoice Logo
                      </Button>

                      <Button variant='primary ms-2' onClick={handleShoweditBox}>
                        Edit Details
                      </Button>
                      {/* <a href="#" className="btn btn-primary">
                 
                   View live <i className="material-icons md-launch"></i>{" "}
                 </a> */}
                    </div>
                  )}

                  <div className='me-0'>
                    {/* <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button> */}
                    {/* <Dropdown1 /> */}
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>Gst No.</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.gstNo}</div>
                    </div>

                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                      
                        <div className='fs-2 fw-bolder'>Country</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.country}</div>
                    </div> */}

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        /> */}
                        <div className='fs-2 fw-bolder'>Address</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.address}</div>
                    </div>
                    {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                      
                        <div className='fs-2 fw-bolder'>Address</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.address}</div>
                    </div> */}
                  </div>
                </div>

                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{width: '50%'}}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* <h3>Social Link</h3> */}
          <div className='me-7 mb-4'>
            <h3>Settings</h3>
            {/* <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl(URL.API_BASE_URL + get_admin_profile?.homeLogo)} alt='' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div> */}
          </div>

          <div className='row'>
            <div className='col-3'>Phone 1 - {get_setting?.phone1}</div>
            <div className='col-3'>Phone 2 - {get_setting?.phone2}</div>
            <div className='col-3'>Email 1 - {get_setting?.email1}</div>
            <div className='col-3'>Email 2 - {get_setting?.email2}</div>
            <div className='col-3'>whatsApp - {get_setting?.whatsApp}</div>
          </div>
          <h3>Social Link</h3>
          <div className='d-flex overflow-auto '>
            <div className='d-flex flex-wrap'>
              {get_setting?.facebook && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.facebook} target='_blank'>
                      <i className='fa-brands fa-facebook-f fs-2x'></i>
                    </a>
                  </div>

                  {/* <div className='fw-bold fs-6 text-gray-400'>{get_admin_profile?.gstNo}</div> */}
                </div>
              )}
              {get_setting?.instagram && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.instagram} target='_blank'>
                      <i className='fa-brands fa-instagram fs-2x'></i>
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.twiter && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.twiter} target='_blank'>
                      <i className='fa-brands fa-twitter fs-2x'></i>
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.youtube && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.youtube} target='_blank'>
                      <i
                        className='fa-brands fa-youtube fs-2x
'
                      ></i>
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.telegram && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.telegram} target='_blank'>
                    <i className="fa-brands fa-telegram fs-2x"></i>
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.skype && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.skype} target='_blank'>
                    <i className="fa-brands fa-skype fs-2x"></i>
                
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.pintrest && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.pintrest} target='_blank'>
                    <i className="fa-brands fa-pinterest fs-2x"></i>
                    {/* <i class="fa-brands fa-pinterest"></i> */}
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.linkedin && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.linkedin} target='_blank'>
                    <i className="fa-brands fa-linkedin fs-2x"></i>
               
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.googleBussiness && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.googleBussiness} target='_blank'>
                    <i className="fa-solid fa-business-time fs-2x"></i>
                   
                    </a>
                  </div>
                </div>
              )}
              {get_setting?.web && (
                <div className=' rounded min-w-125px py-3 px-4 me-6 mb-3'>
                  <div className='d-flex align-items-center'>
                    <a href={get_setting?.web} target='_blank'>
                    <i className="fa-brands fa-chrome fs-2x"></i>
                
                    </a>
                  </div>
                </div>
              )}
            </div>
            {/* <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/overview' && 'active')
                  }
                  to='/crafted/pages/profile/overview'
                >
                  Overview
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/projects' && 'active')
                  }
                  to='/crafted/pages/profile/projects'
                >
                  Projects
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/campaigns' && 'active')
                  }
                  to='/crafted/pages/profile/campaigns'
                >
                  Campaigns
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/documents' && 'active')
                  }
                  to='/crafted/pages/profile/documents'
                >
                  Documents
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/crafted/pages/profile/connections' && 'active')
                  }
                  to='/crafted/pages/profile/connections'
                >
                  Connections
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </div>

      <Footer />
      <PageList />
    </div>
  )
}

export default Setting
